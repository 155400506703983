<ng-template #confirmDialog>
    <mat-dialog-content [style.minWidth.px]="300">
        <div>
            <h1>{{ title }}</h1>
            <div>{{ content }}</div>
            <div class="text-center mt-4 mb-1">
                <button
                    mat-raised-button
                    type="button"
                    (click)="close()"
                    data-testid="confirm-dialog-close-button"
                >
                    Close
                </button>
                <button
                    mat-raised-button
                    class="ml-2 btn btn-primary"
                    type="button"
                    cdkFocusInitial
                    (click)="confirm()"
                    data-testid="confirm-dialog-confirm-button"
                >
                    {{ confirmBtnLabel }}
                </button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>
