import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IDF_ADDRESS } from '@pa/lib-spa';
import { IdfResponse, Organisation, Originator } from '@pa/sdk/idf';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class IdfService {
    constructor(private http: HttpClient, @Inject(IDF_ADDRESS) private idfAddress: string) {}

    saveOriginator(originator) {
        const uriString = `${this.idfAddress}/v1/originators`;
        return this.http.post<IdfResponse<Originator>>(uriString, originator).pipe(
            map((res) => {
                return res.result;
            })
        );
    }

    saveOrganisation(organisation) {
        const uriString = `${this.idfAddress}/v1/organisations?url`;
        return this.http.post<IdfResponse<Organisation>>(uriString, organisation).pipe(
            map((res) => {
                return res.result;
            })
        );
    }
}
