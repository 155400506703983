import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { originatorTypes } from '@pa/references/idf';
import { Behaviour } from '@pa/sdk/idf';
import { ColumnApi, GridApi, GridOptions, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { concat, Subject } from 'rxjs';
import { PolicyService } from '../services/policy.service';
import moment from 'moment';
import { QuoteService } from '../services/quote.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { BehaviourService } from '@pa/lib-spa';

interface colDefs {
  id: string,
  policyNumber: string,
  insuredName: string,
  broker: string,
  effectiveDate: string
}

@Component({
  selector: 'app-quote-policy-home-page',
  templateUrl: './quote-policy-home-page.component.html',
  styleUrls: ['./quote-policy-home-page.component.scss']
})
export class QuotePolicyHomePageComponent implements OnInit {
  behaviour: Behaviour;
  dataLoading: boolean = true;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  paginationPageSize: number = 50
  rowData: colDefs[] = [];
  searchDisabled: boolean = true;
  searchQuery: string = '';
  showBehaviourSelector: boolean = true;
  showTable: boolean = false;
  search$: Subject<string> = new Subject();

  gridOptions: GridOptions = {
    columnDefs: [
      {
        field: 'id',
        hide: true,
      },
      {
        headerName: 'Policy Number',
        field: 'policyNumber',
        flex: 1,
      },
      {
        headerName: 'Insured Name',
        field: 'insuredName',
        flex: 1,
      },
      {
        headerName: 'Broker',
        field: 'broker',
        flex: 1,
      },
      {
        headerName: 'Effective Date',
        field: 'effectiveDate',
        flex: 1,
        valueFormatter: (params: ValueFormatterParams) => {
          if (!params.value) {
            return ''
          }
          const utcTime = moment.utc(params.value);
          let localTime = utcTime.clone();
          return localTime.format('DD MMM YYYY');
        }
      }
    ],
    rowHeight: 48,
    suppressRowHoverHighlight: false,
    overlayNoRowsTemplate: '<span>Data not available</span>',
    singleClickEdit: true,
    rowStyle: { cursor: 'pointer' },
    animateRows: true,
    onRowClicked: (event: RowClickedEvent) => {
      let url: string;
      if (event.data.policyNumber) {
        url = `/clientPolicy?policyId=${event.data.id}&behaviourId=${this.behaviour._id}`;
      } else {
        url = `/viewQuote?proposalId=${event.data.id}&behaviourId=${this.behaviour._id}`;
      }
      this._updateSessionDetails();
      this.router.navigateByUrl(url);
    },
  };

  constructor(
    private policyService: PolicyService,
    private quoteService: QuoteService,
    private router: Router,
    private behaviourService: BehaviourService,
  ) {
    this.search$.pipe(
      debounceTime(1000),
      switchMap((searchText) => {
        this.searchQuery = searchText;
        this._updateSessionDetails();
        if (searchText) {
          this.rowData = [];
        }
        return concat(
          this.quoteService.getESQuotes({ companyName: searchText, behaviourId: this.behaviour._id }),
          this.policyService.getESPolicies({ companyName: searchText, behaviourId: this.behaviour._id })
        )
      })
    ).subscribe({
      next: (res) => {
        this.rowData.push(...this._mapESData(res));
        this.gridApi?.setRowData(this.rowData);
        this.dataLoading = false;
      },
      error: (err) => {
        this.dataLoading = false;
      }
    })
  }

  ngOnInit(): void {
    this.showTable = false;
    if (sessionStorage.getItem('quotePolicyData')) {
      const data = JSON.parse(sessionStorage.getItem('quotePolicyData'));
      this.searchQuery = data.query;
      if (data.behaviour) {
        this.updateBehaviour([data.behaviour]);
      }
      if (data.query) {
        this.search$.next(this.searchQuery);
      }
    }
  }

  updateBehaviour(selectedBehaviours: Behaviour[]) {
    this.behaviour = selectedBehaviours[0];
    this.behaviourService.set(this.behaviour);

    this.rowData = [];
    this.showTable = true;
    this.showBehaviourSelector = false;

    this._updateSessionDetails();

    const today = moment.utc().toISOString();
    const fromDate = moment(today).clone().subtract(30, 'days').toISOString();

    this.dataLoading = true;
    //Search clientproposal and clientpolicy created in last 30 days
    concat(
      this.quoteService.getESQuotes({
        quoteStatus: "none",
        createdDateFrom: fromDate,
        createdDateTo: today,
        behaviourId: this.behaviour._id
      }),
      this.policyService.getESPolicies({
        createdDateFrom: fromDate,
        createdDateTo: today,
        behaviourId: this.behaviour._id
      })
    ).subscribe({
      next: (res) => {

        this.rowData.push(...this._mapESData(res));
        this.gridApi?.setRowData(this.rowData);
        this.searchDisabled = false;
        this.dataLoading = false;
      },
      error: (err) => {
        this.dataLoading = false;
      }
    });
  }

  onGridReady(params): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.applyColumnState({
      state: [{ colId: 'effectiveDate', sort: 'desc' }]
    })
  }

  private _mapESData(data: any[]): any[] {
    return data.map(d => {
      let effectiveDate: string = '';
      if (d.reference) {
        effectiveDate = this._getEffectiveDate([d.inceptionDate, d.amendedDate, d.renewalDate, d.cancellationDate]);
      } else {
        effectiveDate = this._getEffectiveDate([d.inceptionDate, d.amendmentEffectiveDate, d.renewalEffectiveDate, d.cancellationEffectiveDate]);
      }

      let row: colDefs = {
        id: d._id,
        policyNumber: d.reference ? (d.reference + (d.referenceRevision ? ` Revision ${d.referenceRevision}` : '')) : '',
        broker: (d.originator.type === originatorTypes.intermediary) ? d.originator.individual : 'Direct',
        effectiveDate: effectiveDate,
        insuredName: d.company.companyName
      };
      return row;
    });
  }

  private _updateSessionDetails() {
    sessionStorage.setItem('quotePolicyData', JSON.stringify({
      behaviour: this.behaviour,
      query: this.searchQuery
    }));
  }

  private _getEffectiveDate(dates: string[]) {
    return dates.reduce((d1, d2) => {
      if (!d1 && !d2) {
        return
      } else if (!d1) {
        return d2
      } else if (!d2) {
        return d1
      }
      return d1 > d2 ? d1 : d2;
    })
  }
}
