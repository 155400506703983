import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { RouterModule, Routes } from '@angular/router';

import { FormComponentsModule } from '@pa/lib-spa';

import { AuthGuard } from '../core/auth/auth.guard';
import { AddAssetTemplatePage } from './add-asset-template/add-asset-template.page';

const routes: Routes = [
    {
        path: 'rate-management/add-asset-template',
        component: AddAssetTemplatePage,
        canActivate: [AuthGuard],
    },
];

@NgModule({
    imports: [
        CommonModule,
        FormComponentsModule,
        MatButtonModule,
        MatCardModule,
        MatListModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
    ],
    declarations: [AddAssetTemplatePage],
})
export class RateManagementModule {}
