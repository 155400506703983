import { FormlyFieldConfig } from '@ngx-formly/core';

import { Behaviour } from '@pa/sdk/idf';

import { gearTypeDisplay, liftTypeDisplay } from '../../rate-management.types';

export function getAddAssetTemplateFormConfig(behaviour: Behaviour): FormlyFieldConfig[] {
    const behaviourSelection = `${behaviour.product}-${behaviour.insurer}-${behaviour.locale}`;

    const hsabSelections = [
        'aviation-hdi-AU-en',
        'aviation-hdi-NZ-en',
        'aviation-pa-test-AU-en',
    ];
    const hssbSelections = [
        'aviation-hssb-BA-en',
        'aviation-hssb-BG-en',
        'aviation-hssb-CA-en',
        'aviation-hssb-CZ-en',
        'aviation-hssb-DK-en',
        'aviation-hssb-EE-en',
        'aviation-hssb-FI-en',
        'aviation-hssb-HU-en',
        'aviation-hssb-IS-en',
        'aviation-hssb-LT-en',
        'aviation-hssb-LV-en',
        'aviation-hssb-NO-en',
        'aviation-hssb-PL-en',
        'aviation-hssb-RO-en',
        'aviation-hssb-RS-en',
        'aviation-hssb-SE-en',
        'aviation-hssb-SE-sv',
        'aviation-hssb-SI-en',
        'aviation-hssb-SK-en',
    ];
    const mgaSelections = [
        'drone-hdi-AU-en',
        'drone-hdi-NZ-en',
        'drone-pa-test-AU-en',
    ];

    const hsabRequired = hsabSelections.includes(behaviourSelection);
    const hssbRequired = hssbSelections.includes(behaviourSelection);
    const paRequired = mgaSelections.includes(behaviourSelection);

    // NOTE: Separate vars in case the condition ever changes
    const hsabShow = hsabRequired;
    const hssbShow = hssbRequired;
    const paShow = paRequired;

    return [
        {
            key: 'manufacturer',
            type: 'input',
            templateOptions: {
                attributes: {
                    'data-testid': 'manufacturer',
                },
                label: 'Manufacturer',
                required: true,
            },
        },
        {
            key: 'model',
            type: 'input',
            templateOptions: {
                attributes: {
                    'data-testid': 'model',
                },
                label: 'Model',
                required: true,
            },
        },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    key: 'gearType',
                    type: 'select-extended',
                    className: 'col-6',
                    hide: !hsabShow && !hssbShow,
                    templateOptions: {
                        attributes: {
                            'data-testid': 'gearType',
                        },
                        options: Object.entries(gearTypeDisplay).map(([value, display]) => ({ value, label: display })),
                        label: 'Gear Type',
                        required: hssbRequired,
                    },
                    wrappers: ['form-field'],
                },
                {
                    key: 'liftType',
                    type: 'select-extended',
                    className: 'col-6',
                    templateOptions: {
                        attributes: {
                            'data-testid': 'liftType',
                        },
                        options: Object.entries(liftTypeDisplay).map(([value, display]) => ({ value, label: display })),
                        label: 'Lift Type',
                        required: hssbRequired || paRequired,
                    },
                    wrappers: ['form-field'],
                },
            ],
        },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    key: 'defaultCrewSeats',
                    type: 'input',
                    className: 'col-6',
                    templateOptions: {
                        attributes: {
                            'data-testid': 'defaultCrewSeats',
                        },
                        label: 'Default Crew Seats',
                        required: hssbRequired,
                    },
                },
                {
                    key: 'defaultPaxSeats',
                    type: 'input',
                    className: 'col-6',
                    templateOptions: {
                        attributes: {
                            'data-testid': 'defaultPaxSeats',
                        },
                        label: 'Default Passenger Seats',
                        required: hssbRequired,
                    },
                },
            ],
            hide: !hsabShow && !hssbShow,
        },
        {
            key: 'defaultMtom',
            type: 'input',
            hide: !hsabShow && !hssbShow,
            templateOptions: {
                attributes: {
                    'data-testid': 'defaultMtom',
                },
                label: 'Default MTOM',
                required: hssbRequired,
            },
        },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    key: 'weight',
                    type: 'input',
                    className: 'col-6',
                    templateOptions: {
                        attributes: {
                            'data-testid': 'weight',
                        },
                        label: 'Weight (g)',
                        min: 1,
                        required: paRequired,
                        type: 'number',
                    },
                },
                {
                    key: 'mountable',
                    type: 'radio-extended',
                    className: 'col-6',
                    templateOptions: {
                        attributes: {
                            'data-testid': 'mountable',
                        },
                        options: [
                            { value: true, label: 'Yes' },
                            { value: false, label: 'No' },
                        ],
                        required: paRequired,
                        wrapperLabel: 'Mountable? *',
                    },
                    wrappers: ['label'],
                },
            ],
            hide: !paShow,
        },
        {
            fieldGroupClassName: 'row',
            fieldGroup: [
                {
                    key: 'powerSourceType',
                    type: 'input',
                    className: 'col-6',
                    templateOptions: {
                        attributes: {
                            'data-testid': 'powerSourceType',
                        },
                        placeholder: 'Primary Power Source Type',
                    },
                },
                {
                    key: 'powerSourceFuelType',
                    type: 'input',
                    className: 'col-6',
                    templateOptions: {
                        attributes: {
                            'data-testid': 'powerSourceFuelType',
                        },
                        placeholder: 'Primary Power Source Fuel Type',
                    },
                },
            ],
            hide: !paShow,
        },
        {
            key: 'assetReference',
            type: 'input',
            hide: !hsabShow && !hssbShow,
            templateOptions: {
                attributes: {
                    'data-testid': 'assetReference',
                },
                placeholder: 'Asset/Model Reference',
                required: hssbRequired,
            },
        },
        {
            key: 'existingAssetTemplate',
            type: 'input',
            templateOptions: {
                attributes: {
                    'data-testid': 'existingAssetTemplate',
                },
                label: 'Existing Asset Template ID',
                required: true,
            },
        },
    ];
}
