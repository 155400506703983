import { Component, EventEmitter, Input, NgZone, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ThemeService, THEME_NAME } from '@pa/lib-spa';
import { originatorTypes } from '@pa/references/idf';
import { Behaviour, Theme } from '@pa/sdk/idf';
import { sortBy } from 'lodash';
import { IdfService } from 'src/app/services/idf.service';

interface OptionsValue {
    label: string;
    value: string;
}

interface OriginatorValue {
    organisation: string;
    behaviour: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
}

@Component({
    selector: 'add-originator',
    templateUrl: 'add-originator.html',
    styleUrls: ['add-originator.scss'],
})
export class AddOriginator implements OnInit {
    @ViewChild('originatorDialog', { static: false }) originatorDialog: TemplateRef<any> | undefined;
    dialogRef: MatDialogRef<any> | undefined;

    public model: any = {};
    public options = {};
    public form = new FormGroup({});
    public fields: FormlyFieldConfig[] = [
        {
            key: 'organisation',
            type: 'select-extended',
            templateOptions: {
                attributes: {
                    'data-testid': 'organistion',
                },
                label: 'Select a organisation',
                required: true,
                options: [],
            },
            className: 'col-12',
            wrappers: ['form-field'],
            expressionProperties: {
                'templateOptions.options': () => this._organisations,
            },
        },
        {
            key: 'behaviour',
            type: 'select-extended',
            templateOptions: {
                attributes: {
                    'data-testid': 'behaviour',
                },
                label: 'Select a behaviour',
                required: true,
                options: [],
            },
            className: 'col-12',
            wrappers: ['form-field'],
            expressionProperties: {
                'templateOptions.options': () => this._behaviours,
            },
        },
        {
            key: 'firstName',
            type: 'input',
            templateOptions: {
                type: 'text',
                attributes: {
                    'data-testid': 'firstName',
                },
                label: 'Broker First Name',
                required: true,
                pattern: /^[A-Za-z]+$/,
            },
            className: 'col-12',
            wrappers: ['form-field'],
            validation: {
                messages: {
                    pattern: (err: any, field: FormlyFieldConfig) => {
                        return `"${field.form.get('firstName').value}" is not a valid first name`;
                    },
                },
            },
        },
        {
            key: 'lastName',
            type: 'input',
            templateOptions: {
                type: 'text',
                attributes: {
                    'data-testid': 'lastName',
                },
                label: 'Broker Last Name',
                pattern: /^[A-Za-z]+$/,
            },
            className: 'col-12',
            wrappers: ['form-field'],
            validation: {
                messages: {
                    pattern: (err: any, field: FormlyFieldConfig) => {
                        return `"${field.form.get('lastName').value}" is not a valid last name`;
                    },
                },
            },
        },
        {
            key: 'emailAddress',
            type: 'input',
            templateOptions: {
                type: 'text',
                attributes: {
                    'data-testid': 'email',
                },
                label: 'Broker Email',
                required: true,
            },
            className: 'col-12',
            wrappers: ['form-field'],
            validators: {
                validation: [Validators.email],
            },
        },
        {
            key: 'phoneNumber',
            type: 'input',
            templateOptions: {
                type: 'text',
                attributes: {
                    'data-testid': 'phoneNumber',
                },
                label: 'Broker Phone Number',
                required: true,
                pattern: /^[0-9]+$/,
                minLength: 10,
                maxLength: 10,
            },
            className: 'col-12',
            wrappers: ['form-field'],
            validation: {
                messages: {
                    pattern: (err: any, field: FormlyFieldConfig) => {
                        return `"${field.form.get('phoneNumber').value}" is not a valid phone number`;
                    },
                    minLength: (err: any, field: FormlyFieldConfig) => {
                        return `"${field.form.get('phoneNumber').value}" is not a valid phone number`;
                    },
                    maxLength: (err: any, field: FormlyFieldConfig) => {
                        return `"${field.form.get('phoneNumber').value}" is not a valid phone number`;
                    },
                },
            },
        },
    ];

    private theme: Theme;
    public error: boolean = false;
    public loading: boolean = false;
    public showAddOriginator: boolean = true;
    public showOriginator: boolean = false;
    public content: string = '';
    private _organisations: OptionsValue[] = [];
    private _behaviours: OptionsValue[] = [];
    @Input() behaviours: Behaviour[] = [];
    public originator: any;

    constructor(
        private idfService: IdfService,
        public dialog: MatDialog,
        private themeService: ThemeService,
        private ngZone: NgZone
    ) {
        this.theme = themeService.default;
        themeService.theme.subscribe((theme) => {
            this.theme = theme;
        });
    }

    ngOnInit(): void {}

    async open() {
        if (this.originatorDialog) {
            this.showAddOriginator = true;
            this.showOriginator = false;
            this.loading = false;
            this.error = false;
            this.dialogRef = this.dialog.open(this.originatorDialog, {
                panelClass: THEME_NAME,
                width: '50%',
            });
            await this.mapOrganisations();
            await this.mapBehaviours();
        }
    }

    close() {
        this.ngZone.run(() => this.dialogRef?.close());
    }

    private mapBehaviours() {
        this.behaviours.map((b) => {
            this._behaviours.push({
                label: `${b.product} ${b.locale.split('-')[0]} ${b.market}`.toUpperCase(),
                value: `${b._id}`,
            });
        });
        sortBy(this._behaviours, ['label']);
    }

    // TODO: value: should _id of organisation and organisations should be added here
    private async mapOrganisations() {
        this._organisations = [
            {
                label: 'Test Organisation',
                value: '5e2f9c6fce5d6d93a02edc50',
            },
        ];
    }

    async addOriginator() {
        this.loading = true;
        this.showAddOriginator = false;
        this.showOriginator = false;
        this.error = false;

        const originatorObj = this.form.getRawValue() as OriginatorValue;
        const originator = {
            organisation: originatorObj.organisation,
            type: originatorTypes.intermediary,
            individual: `${originatorObj.firstName} ${originatorObj.lastName}`,
            behaviours: [originatorObj.behaviour],
            user: {
                firstName: originatorObj.firstName,
                lastName: originatorObj.lastName,
                emailAddress: originatorObj.emailAddress,
                phoneNumber: originatorObj.phoneNumber,
            },
        };

        this.originator = await this.idfService.saveOriginator(originator).toPromise();

        if (this.originator) {
            this.originator = Object.assign({}, this.originator, {
                link: `http://login.padev.studio/pp?origin=${this.originator.code}`,
                landingUrl: this.originator.organisation?.landingUrl,
            });

            this.loading = false;
            this.showAddOriginator = false;
            this.showOriginator = true;
            this.error = false;
            return;
        }

        this.loading = false;
        this.showAddOriginator = false;
        this.showOriginator = false;
        this.error = true;
        return;
    }
}
