import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BehaviourService } from '@pa/lib-spa';
import { Market, marketDisplay, ProductType } from '@pa/references/idf';
import { iso3166 } from '@pa/references/iso3166';
import { Behaviour } from '@pa/sdk/idf';
import { QuoteService } from 'src/app/services/quote.service';

interface OptionsValue {
  label: string;
  value: string;
}

interface ProductOption {
  label: string;
  value: ProductType;
}

@Component({
  selector: 'app-behaviour-selection',
  templateUrl: './behaviour-selection.component.html',
})
export class BehaviourSelectionComponent implements OnInit {
  @Output() selectedBehaviours = new EventEmitter<Behaviour[]>();

  public loading = true;
  public error = false;
  public fields: FormlyFieldConfig[] = [
    {
      key: 'product',
      type: 'select-extended',
      templateOptions: {
        attributes: {
          'data-testid': 'product',
        },
        label: 'Select a product',
        required: true,
        options: [],
      },
      wrappers: ['form-field'],
      expressionProperties: {
        'templateOptions.options': () => this._products,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          field.formControl.valueChanges.subscribe({
            next: (p) => {
              const behaviours = this._behaviours.filter((b) => b.product === p);
              this._countryOptions(behaviours);
              this._markets = this.distinct(behaviours.map((b) => b.market));
              if (behaviours?.length === 1) {
                this._updateBehaviour(behaviours[0]);
              }
              const countryField = field.parent.fieldGroup.find(fg => fg.key === 'country');
              countryField?.formControl.setValue("");
              countryField.form.markAsUntouched();
            },
          });
        },
      },
    },
    {
      key: 'country',
      type: 'select-extended',
      templateOptions: {
        attributes: {
          'data-testid': 'country',
        },
        label: 'Country',
        required: true,
        options: [],
      },
      wrappers: ['form-field'],
      hideExpression: () => this._countries.length < 2,
      expressionProperties: {
        'templateOptions.options': () => this._countries,
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          field.formControl.valueChanges.subscribe({
            next: (c) => {
              const behaviours = this._behaviours.filter((b) => b.product === this.model.product && b.locale.includes(c));
              if (c) {
                this._languageOptions(behaviours);
                this._marketOptions(behaviours);
                this._markets = this.distinct(behaviours.map((b) => b.market));
              } else {
                this._markets = []
              }
              const marketField = field.parent.fieldGroup.find(fg => fg.key === 'market');
              marketField?.formControl.setValue("");
              marketField.form.markAsUntouched();
              if (behaviours?.length === 1) {
                this._updateBehaviour(behaviours[0]);
              }
            },
          });
        },
      },
    },
    {
      key: 'language',
      type: 'select-extended',
      templateOptions: {
        attributes: {
          'data-testid': 'language',
        },
        label: 'Select your language',
        required: true,
        options: [],
      },
      wrappers: ['form-field'],
      hideExpression: () => this._languages.length < 2,
      expressionProperties: {
        'templateOptions.options': (model: any) => (model.country ? this._languages : []),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          field.formControl.valueChanges.subscribe({
            next: (c) => {
              this._updateBehaviour();
            },
          });
        },
      },
    },
    {
      key: 'market',
      type: 'select-extended',
      templateOptions: {
        attributes: {
          'data-testid': 'market',
        },
        label: 'Select your market',
        required: true,
        options: [],
      },
      wrappers: ['form-field'],
      hideExpression: (model: any) => !model.language || this._markets.length < 2,
      expressionProperties: {
        'templateOptions.options': () =>
          this._markets
            .map((m) => ({ label: marketDisplay[m], value: m }))
            .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0)),
      },
      hooks: {
        onInit: (field: FormlyFieldConfig) => {
          field.formControl.valueChanges.subscribe({
            next: (c) => {
              this._updateBehaviour();
            },
          });
        },
      },
    },
  ];
  public model: any = {};
  public options = {};
  public form = new FormGroup({});

  private _countryMap: OptionsValue[] = iso3166.map((i) => ({ label: i.name, value: i.code }));
  private _languageMap: OptionsValue[] = [
    { label: 'English', value: 'en' },
    { label: 'German', value: 'de' },
    { label: 'Swedish', value: 'sv' },
  ];
  private _productMap: ProductOption[] = [
    { label: 'Drones', value: 'drone' },
    { label: 'Aviation', value: 'aviation' },
  ];
  private _behaviours: Behaviour[] = [];
  private _languages: OptionsValue[] = [];
  private _products: ProductOption[] = [];
  private _countries: OptionsValue[] = [];
  private _markets: Market[] = [];

  constructor(
    private _behaviourService: BehaviourService,
    private quoteService: QuoteService,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.quoteService.getBehavioursfromCognito()
      .subscribe({
        next: (behaviours) => {
          this._behaviours.push(...behaviours);
          this._products = this._productMap.filter((p) => this._behaviours.map((b) => b.product).includes(p.value));
          if (this._products.length === 1) {
            this.model.product = this._products[0].value;
          }
          if (this._countries.length === 1) {
            this._marketOptions(this._behaviours);

            if (this._languages.length === 1 && this._markets.length === 1) {
              this.submit();
            }
          }
          this.error = false;
          this.loading = false;
        },
        error: () => {
          this.loading = false
          this.error = true
        },
      });
  }

  private _countryOptions(behaviours: Behaviour[]) {
    const countries = behaviours.map((b) => b.locale.split('-')[0]);
    this._countries = this._countryMap.filter((c) => countries.includes(c.value));
    if (this._countries.length === 1) {
      this.model.country = this._countries[0].value;
      this._languageOptions(behaviours.filter((b) => b.locale.includes(this.model.country)));
    }
    this._marketOptions(behaviours);
  }

  private _languageOptions(behaviours: Behaviour[]) {
    const languages = behaviours.map((b) => b.locale.split('-').pop());

    this._languages = this._languageMap.filter((l) => languages.includes(l.value));
    if (this._languages.length === 1) {
      this.model.language = this._languages[0].value;
    }
  }

  private _marketOptions(behaviours: Behaviour[]) {
    const markets = this.distinct(behaviours.map((b) => b.market));

    this._markets = markets;
    if (markets.length === 1) {
      this.model.market = markets[0];
    } else {
      delete this.model.market;
    }
  }

  private _updateBehaviour(override?: Behaviour) {
    let behaviour: Behaviour;
    if (override) {
      behaviour = override;
    } else {
      const { product, country, language, market } = this.model;
      behaviour = this._behaviours.find(
        (b) => b.product === product && b.locale === `${country}-${language}` && b.market === market
      );
    }
    if (behaviour) {
      this._behaviourService.set(behaviour);
    }
  }

  submit() {
    const { product, country, language, market } = this.model;
    const behaviour = this._behaviours.find(
      (b) => b.product === product && b.locale === `${country}-${language}` && b.market === market
    );

    if (!behaviour) {
      console.error(product, country, language, market);
      throw new Error('Invalid selection');
    }

    this.selectedBehaviours.emit([behaviour]);
  }

  private distinct = <T>(arr: T[]): T[] => arr.filter((value, index, self) => self.indexOf(value) === index);
}