import { Subject } from 'rxjs';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { DECLINE_REASONS } from '../const/const';
import { FormlyHookFn, FormlyLifeCycleOptions } from '@ngx-formly/core/lib/components/formly.field.config';

export const declineModal = (decline: () => void): FormlyFieldConfig => ({
    type: 'modal-form',
    key: 'declineModal',
    templateOptions: {
        asyncLoading: false,
        attributes: {
            'data-testid': 'declineReasonDialog',
        },
        buttonOptions: {
            'data-testid': 'DeclineReason',
            text: 'Decline',
        },
        hidden: true,
        label: '',
        listener: new Subject(),
        modalClass: 'hdi-theme',
        minWidth: '600',
        submitEvent: 'decline',
    },
    hooks: {
        onInit: (fieldConfig: FormlyFieldConfig) => {
            fieldConfig.templateOptions?.listener?.subscribe({
                next: (event: string) => {
                    console.log('event', event, fieldConfig);
                    switch (event) {
                        case 'decline':
                            decline();
                            break;
                    }
                },
            });
        },
    } as FormlyLifeCycleOptions<FormlyHookFn>,
    fieldGroup: [
        {
            className: 'col-md-12',
            key: 'declineReasonSelect',
            type: 'select-extended',
            expressionProperties: {
                'templateOptions.required': 'model?.declineReasonInput === null || model?.declineReasonInput===""',
            },
            templateOptions: {
                attributes: {
                    'data-testid': 'declineReasonSelect',
                },
                label: 'Decline Reasons',
                required: false,
                options: DECLINE_REASONS,
            },
            wrappers: ['form-field'],
        },
        {
            className: 'col-md-12',
            key: 'declineReasonInput',
            type: 'input',
            expressionProperties: {
                'templateOptions.required': 'model?.declineReasonSelect === null || model?.declineReasonSelect===""',
            },
            templateOptions: {
                attributes: {
                    'data-testid': 'declineReasonInput',
                },
                label: 'Free Text Decline Reasons',
                type: 'textarea',
            },
            wrappers: ['form-field'],
        },
    ],
});
