<div>
    <h1>Claims Management</h1>
    <mat-card class="mb-3">
        <mat-card-header>
            <mat-card-title> Policy Details </mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row justify-content-center" *ngIf="loading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <table class="mat-table" *ngIf="!loading">
                <tr class="mat-row">
                    <th class="mat-header-cell">Insured Name: </th>
                    <td class="mat-cell">{{ basicInfo.companyName }}</td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Policy Reference: </th>
                    <td class="mat-cell">{{ basicInfo.reference }}</td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Period of Insurance: </th>
                    <td class="mat-cell">{{ basicInfo.renewalDate }} - {{ basicInfo.expiryDate }}</td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Date of Loss:</th>
                    <td class="mat-cell">{{ basicInfo.dateOfLoss }}</td>
                </tr>
                <br/>
                <tr class="mat-row">
                    <th class="mat-header-cell">FNOL Documentation: </th>
                    <td class="mat-cell">
                        <mat-list-item *ngFor="let document of basicInfo.documents"
                            ><a [href]="document.url" target="_blank"> {{ document.display }}</a></mat-list-item
                        >
                    </td>
                </tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>
