<h1>Dashboard</h1>

<div *ngIf="showForms | async">
    <mat-card class="mb-3" >
        <mat-card-content>
            <div id="addAssetsForm"></div>
        </mat-card-content>
    </mat-card>

    <mat-card class="mb-3">
        <div id="addBrokerForm"></div>
    </mat-card>
</div>
