<div class="nav-container">
    <mat-toolbar color="medium" class="nav-toolbar">
        <div class="nav-button">
            <button mat-icon-button (click)="snav.toggle()" color="primary">
                <mat-icon>menu</mat-icon>
            </button>
        </div>
        <span class="nav-spacer"></span>
        <img class="md-title text-center nav-logo" [src]="logo" />
        <span class="nav-spacer"></span>
        <div class="nav-button-right" (click)="signOut()">
            <button mat-raised-button class="btn btn-primary">Sign Out</button>
        </div>
    </mat-toolbar>

    <mat-sidenav-container class="nav-sidenav-container">
        <mat-sidenav #snav [mode]="'side'" fixedTopGap="56">
            <mat-nav-list>
                <span *ngFor="let item of menu">
                    <span *ngIf="item.children && item.children.length > 0">
                        <mat-accordion>
                            <mat-expansion-panel>
                                <mat-expansion-panel-header>
                                    <mat-panel-title>
                                        <div fxLayout="row" fxLayoutAlign="space-between center" class="nav-item">
                                            <mat-icon>{{ item.iconName }}</mat-icon>
                                            <mat-label>{{ item.displayName }}</mat-label>
                                        </div>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <span *ngFor="let child of item.children">
                                    <mat-list-item routerLink="[child.route]">
                                        <div fxLayout="row" fxLayoutAlign="space-between center" class="nav-item">
                                            <mat-icon>{{ child.iconName }}</mat-icon>
                                            <mat-label>{{ child.displayName }} </mat-label>
                                        </div>
                                    </mat-list-item>
                                </span>
                            </mat-expansion-panel>
                        </mat-accordion>
                    </span>
                    <span *ngIf="!item.children || item.children.length === 0">
                        <mat-list-item routerLink="{{ item.route }}">
                            <div fxLayout="row" fxLayoutAlign="space-between" class="nav-item">
                                <mat-icon>{{ item.iconName }}</mat-icon>
                                {{ item.displayName }}
                            </div>
                        </mat-list-item>
                    </span>
                </span>
            </mat-nav-list>
        </mat-sidenav>

        <mat-sidenav-content #snavContent class="nav-content-container">
            <router-outlet></router-outlet>
            <footer></footer>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
