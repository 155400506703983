import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { DashboardPage } from './core/dashboard/dashboard.page';

import { UnderwritingComponent } from './underwriting/underwriting.component';
import { ClientQuoteComponent } from './client-quotes/client-quote.component';
import { DistributionManagementComponent } from './distribution-management/distribution-management.component';
import { ViewOrganisationComponent } from './view-organisation/view-organisation.component';
import { ViewQuoteComponent } from './view-quote/view-quote.component';
import { ViewPolicyComponent } from './view-policy/view-policy.component';
import { QuotePolicyHomePageComponent } from './quote-policy-home-page/quote-policy-home-page.component';

const routes: Routes = [
    { path: '', component: DashboardPage, canActivate: [AuthGuard] },
    {
        path: 'underwriting',
        component: UnderwritingComponent,
    },
    {
        path: 'clientQuote',
        component: ClientQuoteComponent,
    },
    {
        path: 'distributionManagement',
        component: DistributionManagementComponent
    },
    {
        path: 'viewOrganisation',
        component: ViewOrganisationComponent
    },
    {
        path: 'viewQuote',
        component: ViewQuoteComponent
    },
    {
        path: 'clientPolicy',
        component: ViewPolicyComponent
    },
    {
        path: 'quotePolicyHome',
        component: QuotePolicyHomePageComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule { }
