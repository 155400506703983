import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { GridOptions, RowClickedEvent } from 'ag-grid-community';

@Component({
    selector: 'app-view-organisation',
    templateUrl: './view-organisation.component.html',
    styleUrls: ['./view-organisation.component.scss'],
})
export class ViewOrganisationComponent implements OnInit {
    public model: any = {};
    public form = new FormGroup({});
    public fields: FormlyFieldConfig[] = [
        {
            key: 'originator',
            type: 'ag-grid',
            defaultValue: [],
            templateOptions: {
                attributes: {
                    'data-testid': 'originatorTable',
                },
                gridOptions: {
                    columnDefs: [
                        {
                            headerName: 'Broker Name',
                            field: 'originatorName',
                        },
                        {
                            headerName: 'Email Address',
                            field: 'originatorEmail',
                        },
                        {
                            headerName: 'Applicable Markets',
                            field: 'originatorMarkets',
                        },
                    ],
                    rowHeight: 48,
                    overlayNoRowsTemplate: '<span>No Originators are there to show</span>',
                    onRowClicked: (event: RowClickedEvent) => {
                        console.log(event.type);
                    },
                } as GridOptions,
            },
        },
    ];

    constructor() {}

    ngOnInit(): void {}
}
