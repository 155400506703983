<div *ngIf="loading" class="row justify-content-center">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="!loading" class="mb-5">
    <div id="title-bar">
        <span class="col-md-2">
            <a data-testid="back-to-search-results" routerLink="/quotePolicyHome">
                << Back to search results</a>
        </span>
        <span class="col-md-8">
            <h2> Transaction Type: {{ clientProposal.transactionType }}</h2>
        </span>
        <span class="col-md-2" hidden></span>
    </div>
    <mat-card class="my-3">
        <div class="row">
            <div class="col-6">
                <mat-card-header>
                    <mat-card-title>Quote Details</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="mat-table">
                        <tr>
                            <th class="mat-header-cell">Insured Name</th>
                            <td class="mat-cell" data-testid="InsuredName">{{ clientProposal.company.companyName }}</td>
                        </tr>
                        <tr>
                            <th class="mat-header-cell">Quote Date/Time</th>
                            <td class="mat-cell" data-testid="quoteDateTime">{{ formatDate(clientQuote.referralDecision?.date) }}</td>
                        </tr>
                        <tr *ngIf="!isNB">
                            <th class="mat-header-cell">Policy Number</th>
                            <td class="mat-cell" data-testid="policyNumber">{{ policyNumber }}</td>
                        </tr>
                        <tr *ngIf="isNB">
                            <th class="mat-header-cell">Proposed Inception Date</th>
                            <td class="mat-cell" data-testid="proposedInceptionDate">{{ formatDate(clientProposal.inceptionDate) }}</td>
                        </tr>
                        <tr *ngIf="!isNB">
                            <th class="mat-header-cell">Inception Date</th>
                            <td class="mat-cell" data-testid="inceptionDate">{{ formatDate(clientProposal.inceptionDate) }}</td>
                        </tr>
                        <tr *ngIf="!isNB">
                            <th class="mat-header-cell">Proposed effective Date</th>
                            <td class="mat-cell" data-testid="proposedEffectiveDate">{{ formatDate(proposedEffectiveDate) }}</td>
                        </tr>
                        <tr *ngIf="!isNB">
                            <th class="mat-header-cell">Expiry Date</th>
                            <td class="mat-cell" data-testid="expiryDate">{{ formatDate(clientQuote.expiryDate) }}</td>
                        </tr>
                        <tr *ngIf="showBroker">
                            <th class="mat-header-cell">Broker Name</th>
                            <td class="mat-cell" data-testid="brokerName">{{ originator.individual }}</td>
                        </tr>
                        <tr *ngIf="clientProposal.interestedParties.length">
                            <th class="mat-header-cell">Policy-Wide Interested Parties</th>
                            <td class="mat-cell">
                                <mat-list-item *ngFor="let ip of clientProposal.interestedParties">{{ ip.name }}
                                </mat-list-item>
                            </td>
                        </tr>
                        <tr>
                            <th class="mat-header-cell">Hold cover</th>
                            <td class="mat-cell">
                                <mat-list-item *ngFor="let reason of clientProposal.holdCoverReasons">{{ reason }}
                                </mat-list-item>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </div>
            <div class="col-6">
                <mat-card-header>
                    <mat-card-title>Referral Details</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="mat-table">
                        <tr>
                            <th class="mat-header-cell">Referral</th>
                            <td class="mat-cell"  data-testid="referral">{{ isReferral }}</td>
                        </tr>
                        <tr *ngIf="isReferral === 'Yes'">
                            <th class="mat-header-cell">Referral Date</th>
                            <td class="mat-cell"  data-testid="referralDate">{{ formatDate(clientQuote.referralDecision.date) }}</td>
                        </tr>
                        <tr *ngIf="isReferral === 'Yes'">
                            <th class="mat-header-cell">Referral Status</th>
                            <td class="mat-cell"  data-testid="referralStatus">{{ clientQuote.status }} by {{
                                formatNameFromEmail(clientQuote.referralDecision.by) }} </td>
                        </tr>
                        <tr *ngIf="isReferral === 'Yes'">
                            <td class="mat-cell">
                                <a mat-raised-button [href]="referralPageLink" target="_blank"
                                    style="text-decoration: none;">
                                    Referral page
                                </a>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </div>
        </div>
    </mat-card>
    <div class="row my-3 mx-2">
        <h2>Documentation Links</h2>
        <table>
            <li *ngFor="let attachment of attachments">
                <a [href]="attachment.url" target="_blank"> {{ attachment.fileName }}</a>
            </li>
        </table>
    </div>
</div>