import { Component, OnInit } from '@angular/core';
import { AUTH_STATE } from './const/const';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from './core/auth/auth.service';
import { THEME_NAME, ThemeService } from '@pa/lib-spa';
import { AuthState, CognitoUserInterface, onAuthUIStateChange } from '@aws-amplify/ui-components';
import version  from '../../package.json';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    authState: boolean = false;
    public theme: string;
    public themeName = THEME_NAME
    public version = version;


    constructor(private router: ActivatedRoute, private authService: AuthService, private themeService: ThemeService) {}

    ngOnInit() {
        this.router.queryParams.subscribe((params) => {});
        this.themeService.theme.subscribe((theme) => {
            this.theme = theme.slug;
        });

        onAuthUIStateChange((state: AuthState, data: CognitoUserInterface) => {
            this.authState = state === AUTH_STATE;
            this.authService.userSignedInSubject.next(this.authState);
            this.authService.setAuthState(state);
            this.authService.setAuthData(data);
        });
    }
}
