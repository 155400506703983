<mat-card class="mb-3">
    <mat-card-title>Premium</mat-card-title>
    <mat-card-content>
        <div class="col-md-12">
            <ag-grid-angular
                domLayout="autoHeight"
                class="ag-theme-material"
                [gridOptions]="premiumsGridOptions"
                [rowData]="asset.premiums"
                (rowDataChanged)="rowDataChanged($event, premiumsGridOptions, true)"
                (gridSizeChanged)="resize($event, premiumsGridOptions)"
                [getRowStyle]="getRowStyle"
            ></ag-grid-angular>
        </div>
    </mat-card-content>
</mat-card>
