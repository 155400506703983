import _ from 'lodash';
import { Component, EventEmitter, Input, NgZone, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { THEME_NAME, ThemeService } from '@pa/lib-spa';
import { Theme } from '@pa/sdk/idf';

@Component({
    selector: 'confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
})
export class ConfirmDialogComponent implements OnInit {
    @ViewChild('confirmDialog', { static: false }) confirmDialog: TemplateRef<any> | undefined;
    @Input() title: string = 'Confirm';
    @Input() confirmBtnLabel: string = 'Confirm';
    @Output() confirmClicked = new EventEmitter();
    @Output() closeClicked = new EventEmitter();
    dialogRef: MatDialogRef<any> | undefined;

    theme: Theme;
    content: string = '';

    constructor(public dialog: MatDialog, private themeService: ThemeService, private ngZone: NgZone) {
        this.theme = themeService.default;
        themeService.theme.subscribe((theme) => {
            this.theme = theme;
        });
    }

    ngOnInit() {}

    open(content: string) {
        this.content = content;
        if (this.confirmDialog) {
            this.dialogRef = this.dialog.open(this.confirmDialog, {
                data: {
                    content,
                    title: this.title,
                    confirmBtnLabel: this.confirmBtnLabel,
                },
                panelClass: THEME_NAME,
            });
        }
    }
    close() {
        this.closeClicked.emit();
        this.ngZone.run(() => this.dialogRef?.close());
    }

    confirm() {
        this.confirmClicked.emit();
        this.close();
    }
}
