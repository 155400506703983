import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig, FormlyForm } from '@ngx-formly/core';
import { GridApi, GridOptions, RowClickedEvent, ValueFormatterParams } from 'ag-grid-community';
import { Auth } from 'aws-amplify';
import _ from 'lodash';
import moment from 'moment-timezone';

import { validateAssetQuote } from '@pa/lib-validators/assetQuotes';
import { getAssetEndorsements } from '@pa/lib-validators';
import {
    CurrencyCode,
    DiscountType,
    EndorsementAppliesTo,
    EndorsementCategory,
    formatCurrencyAmountByFormatter,
    InterestedPartyNotedOn,
    PriceType,
    QuoteStatus,
    TransactionType,
    interestedPartyTypeMapping,
    transactionTypeMapping,
    referralTypeMapping,
    ClientReferralType,
    originatorTypes,
    UnderwritingActions,
} from '@pa/references/idf';
import { assetTypes, AssetType } from '@pa/references/paul-precision';
import { ClientPolicy, Endorsement, InsurerAssetTemplate } from '@pa/sdk/cmf';
import {
    AircraftProposal,
    AssetProposal,
    AssetQuote,
    Behaviour,
    ClientProposal,
    ClientProposalEndorsement,
    ClientQuote,
    Originator,
    ProposalInterestedParty,
} from '@pa/sdk/idf';
import { User } from '@pa/sdk/rmf';
import { BehaviourService } from '@pa/lib-spa';

import { AuthService } from '../core/auth/auth.service';
import { QuoteService } from '../services/quote.service';
import { NotificationService } from '../common/notification.service';
import { ConfirmDialogComponent } from '../components/dialogs/confirm-dialog.component';
import { getCurrencyFormatter } from '../core/utils/value.formatters';
import { PolicyService } from '../services/policy.service';
import { AgGridRowEvents, AgGridRowEventVars, getAssetGridColumns } from './utils/ag-grid';
import { declineModal } from './decline.modal';
import { EditAssetModalComponent } from './edit-asset/asset.modal';
import { AssetModel } from './edit-asset/types';
import { getEndorsementGridOptions } from './endorsement';
import { filterZeroPrices } from './utils/endorsements';
import { PolicyReferral, ProposalReferrals } from './types';
import { policyEndorsementsValidator } from './utils/validators';
import { concatMap, map } from 'rxjs/operators';
import { forkJoin, from, Observable, of } from 'rxjs';
import { SdkService } from '../services/sdk.service';
import { Asset, Pilot } from '@pa/sdk/rmf';
import { feesGridOptions } from './utils/fees';

export enum AssetCheck {
    hull = 'hull',
    tpl = 'tpl',
    csl = 'csl',
    pll = 'pll',
    endorsements = 'endorsements',
}

@Component({
    selector: 'underwriting',
    templateUrl: './underwriting.component.html',
    styleUrls: ['underwriting.component.scss'],
})
export class UnderwritingComponent implements OnInit {
    @ViewChild('formlyForm') formlyForm?: FormlyForm;
    @ViewChild('editAssetModal') editAssetModal: EditAssetModalComponent;
    @ViewChild('confirmDialog') confirmDialog: ConfirmDialogComponent;

    constructor(
        private router: ActivatedRoute,
        private authService: AuthService,
        private behaviourService: BehaviourService,
        private quoteService: QuoteService,
        private policyService: PolicyService,
        private notificationService: NotificationService,
        private changeDetectorRef: ChangeDetectorRef,
        private _sdk: SdkService
    ) {
        this.referrals = {
            assetReferrals: [],
            policyReferrals: [],
        };
    }

    agGridRowEventVars: AgGridRowEventVars = {};
    behaviour: Behaviour;
    behaviourId: string = '';
    broker: User;
    brokerShow = false;
    clientPolicy?: ClientPolicy;
    currencyFormatter: Intl.NumberFormat = getCurrencyFormatter(CurrencyCode.AUD);
    discountRate: number = 0;
    existingAssets: Asset[] | undefined;
    existingPilots: Pilot[] | undefined;
    loadingProposal = true;
    transactionType: string;
    quoteStatus: string;
    referralReasons: string[];
    referrals: ProposalReferrals;
    notes: string[] = [];
    isNoteDisabled: boolean = true;
    EditSave: string = 'Edit';

    endorsements: Endorsement[] = [];
    fields: FormlyFieldConfig[] = [];

    form: any = new FormControl();
    insurerAssetTemplates: InsurerAssetTemplate[] = [];
    model: any = {};
    processing: boolean = false;
    proposal: any = {};
    proposalId: string = '';
    action?: UnderwritingActions;
    showUnderwritingButton = false;
    uForm: FormGroup = new FormGroup({});

    public get isValidToAccept(): boolean {
        return this.formlyForm.form.valid && !this.processing;
    }

    ngOnInit() {
        this.router.queryParams.subscribe((params) => {
            this.proposalId = params.proposalId;
            this.behaviourId = params.behaviourId;
            this.quoteService
                .getBehaviour(this.behaviourId)
                .toPromise()
                .then((b) => {
                    this.behaviourService.set(b);
                });
        });
        this.authService.userSignedInSubject.subscribe((hasSigned) => {
            if (hasSigned && this.proposalId && this.behaviourId) {
                this.loadingProposal = true;
                this.processing = true;

                this.getQuote().subscribe({
                    next: () => {
                        this.loadingProposal = false;
                        this.processing = false;

                        // NOTE: Form only loads after the data fetch, if performance is an issue then we may need to add
                        // a spinner or other indicator here.
                        this.fields = this._formlyFieldConfig(this.clientPolicy);
                        this.checkShowUnderwritingButton();
                        this.changeDetectorRef.detectChanges();

                        Object.values(assetTypes).forEach((assetType: AssetType) => {
                            const agGridField = this.getAssetGridField(assetType);
                            const gridApi = agGridField?.templateOptions?.gridOptions?.api;
                            gridApi.refreshCells({ force: true });
                            this.updateAssetTypeTotalPremium(gridApi, assetType);
                        });
                    },
                    error: (err) => {
                        this.loadingProposal = false;
                        console.error(err);
                    },
                });
            }
        });
    }

    private _formlyFieldConfig(clientPolicy?: ClientPolicy): FormlyFieldConfig[] {
        return [
            {
                key: 'clientQuote.fees',
                type: 'ag-grid',
                hideExpression: (model) => !model.length,
                defaultValue: [],
                templateOptions: {
                    wrapperTitle: 'Fees',
                    gridOptions: feesGridOptions(this.currencyValueFormatter),
                },
                wrappers: ['card'],
            },
            {
                key: 'policyEndorsements',
                type: 'ag-grid',
                defaultValue: [],
                templateOptions: {
                    wrapperTitle: 'Policy Wide Endorsements',
                    gridOptions: getEndorsementGridOptions(
                        this.model.transactionType,
                        this.currencyValueFormatter,
                        this.model,
                        clientPolicy
                    ),
                },
                wrappers: ['card'],
                validators: {
                    validation: [policyEndorsementsValidator(this.model)],
                },
                hooks: {
                    onInit: (field) => {
                        const gridOptions: GridOptions = field.templateOptions.gridOptions;
                        gridOptions.onRowDataChanged = (params) => {
                            const policyEndTypes: PriceType[] = (this.model.policyEndorsements as Endorsement[]).map(
                                (e) => e.type
                            );
                            const totalPremium = _.sum(
                                (this.model.clientQuote as ClientQuote).priceYearAdjusted
                                    .filter((pya) => policyEndTypes.includes(pya.type))
                                    .map((pya) => pya.liability || pya.standard)
                            );
                            gridOptions.api.setPinnedBottomRowData(
                                totalPremium
                                    ? [
                                          {
                                              title: 'Total Policy Wide Endorsement Premium',
                                              totalPremium,
                                              totalProRata: _.round(totalPremium * (1 - this.discountRate), 2),
                                          },
                                      ]
                                    : []
                            );
                        };
                    },
                },
            },
            {
                key: 'clientQuote.aircraftQuotes',
                type: 'ag-grid',
                defaultValue: [],
                hideExpression: (model) => !model.length,
                templateOptions: {
                    wrapperTitle: 'Aircraft Table',
                    gridOptions: {
                        ...getAssetGridColumns(
                            this.agGridRowEventVars,
                            this.validateAssetQuote,
                            this.currencyValueFormatter,
                            assetTypes.aircraft,
                            this.model.transactionType
                        ),
                        autoWidth: 'content',
                        onRowClicked: (event: RowClickedEvent) => {
                            if (this.agGridRowEventVars.action === AgGridRowEvents.Edit) {
                                this.editAsset(event);
                            }
                            delete this.agGridRowEventVars.action;
                        },
                        singleClickEdit: true,
                        animateRows: true,
                    },
                },
                wrappers: ['card'],
                validators: {
                    validation: [
                        (control: AbstractControl): any => {
                            const invalid = control.value?.some((aq) => !this.validateAssetQuote(aq));
                            return invalid ? { 'clientQuote.aircraftQuotes': true } : null;
                        },
                    ],
                },
            },
            {
                key: 'clientQuote.uavQuotes',
                type: 'ag-grid',
                defaultValue: [],
                hideExpression: (model) => !model.length,
                templateOptions: {
                    wrapperTitle: 'Drone Table',
                    gridOptions: {
                        ...getAssetGridColumns(
                            this.agGridRowEventVars,
                            this.validateAssetQuote,
                            this.currencyValueFormatter,
                            assetTypes.uav,
                            this.model.transactionType
                        ),
                        autoWidth: 'content',
                        onRowClicked: (event: RowClickedEvent) => {
                            // "(event.event as any).offsetX" is to fix an issue where
                            // an empty modal will be opened when triggering editing of policy wide endorsement premium
                            // Jira ticket: PP-1676
                            if (
                                this.agGridRowEventVars.action === AgGridRowEvents.Edit &&
                                (event.event as any).offsetX
                            ) {
                                this.editAsset(event);
                            }
                            delete this.agGridRowEventVars.action;
                        },
                        singleClickEdit: true,
                        animateRows: true,
                    },
                },
                wrappers: ['card'],
                validators: {
                    validation: [
                        (control: AbstractControl): any => {
                            const invalid = control.value?.some((aq) => !this.validateAssetQuote(aq));
                            return invalid ? { 'clientQuote.uavQuotes': true } : null;
                        },
                    ],
                },
            },
            {
                key: 'clientQuote.payloadQuotes',
                type: 'ag-grid',
                defaultValue: [],
                hideExpression: (model) => !model.length,
                templateOptions: {
                    wrapperTitle: 'Payloads Table',
                    gridOptions: {
                        ...getAssetGridColumns(
                            this.agGridRowEventVars,
                            this.validateAssetQuote,
                            this.currencyValueFormatter,
                            assetTypes.uavPayload,
                            this.model.transactionType
                        ),
                        autoWidth: 'content',
                        onRowClicked: (event: RowClickedEvent) => {
                            if (this.agGridRowEventVars.action === AgGridRowEvents.Edit) {
                                this.editAsset(event);
                            }
                            delete this.agGridRowEventVars.action;
                        },
                        singleClickEdit: true,
                        animateRows: true,
                    },
                },
                wrappers: ['card'],
                validators: {
                    validation: [
                        (control: AbstractControl): any => {
                            const invalid = control.value?.some((aq) => !this.validateAssetQuote(aq));
                            return invalid ? { 'clientQuote.payloadQuotes': true } : null;
                        },
                    ],
                },
            },
            {
                key: 'clientQuote.equipmentQuotes',
                type: 'ag-grid',
                defaultValue: [],
                hideExpression: (model) => !model.length,
                templateOptions: {
                    wrapperTitle: 'Equipment Table',
                    gridOptions: {
                        ...getAssetGridColumns(
                            this.agGridRowEventVars,
                            this.validateAssetQuote,
                            this.currencyValueFormatter,
                            assetTypes.uavEquipment,
                            this.model.transactionType
                        ),
                        autoWidth: 'content',
                        onRowClicked: (event: RowClickedEvent) => {
                            if (this.agGridRowEventVars.action === AgGridRowEvents.Edit) {
                                this.editAsset(event);
                            }
                            delete this.agGridRowEventVars.action;
                        },
                        singleClickEdit: true,
                        animateRows: true,
                    },
                },
                wrappers: ['card'],
                validators: {
                    validation: [
                        (control: AbstractControl): any => {
                            const invalid = control.value?.some((aq) => !this.validateAssetQuote(aq));
                            return invalid ? { 'clientQuote.equipmentQuotes': true } : null;
                        },
                    ],
                },
            },
            {
                templateOptions: {
                    wrapperTitle: 'Subjectivities',
                },
                fieldGroupClassName: 'row',
                fieldGroup: [
                    {
                        className: 'col-11',
                        key: 'notes',
                        type: 'textarea',
                        templateOptions: {
                            wrapperLabel: 'Policy/quote notes',
                            maxLength: 5000,
                            rows: 2,
                        },
                    },
                    {
                        className: 'col-1',
                        type: 'button',
                        templateOptions: {
                            text: 'Add',
                            onClick: () => {
                                if (!!this.model.notes) {
                                    this.notes.push(this.model.notes);
                                    this.model.notes = '';
                                }
                                this.uForm.get('notes').setValue('');
                            },
                        },
                    },
                ],
                wrappers: ['card'],
            },
            declineModal(() => this.decline()),
        ];
    }

    getQuote() {
        return this.quoteService.getProposal(this.proposalId, this.behaviourId).pipe(
            concatMap((proposal) => {
                this.currencyFormatter = getCurrencyFormatter(proposal.currency);

                this.brokerShow = (proposal.originator as Originator).type === originatorTypes.intermediary;

                let intermediaryUser$: Observable<User | undefined> = of(undefined);
                if (this.brokerShow) {
                    intermediaryUser$ = this.quoteService.getUser(
                        this.behaviourId,
                        (proposal.originator as Originator)?.user as string
                    );
                }

                const isRetrievingPolicy =
                    [TransactionType.amendment, TransactionType.cancellation, TransactionType.renewal].includes(
                        proposal.transactionType
                    ) && proposal.clientPolicy;

                let clientPolicy$: Observable<ClientPolicy | undefined> = of(undefined);
                if (isRetrievingPolicy) {
                    clientPolicy$ = this.policyService.getPolicy(proposal.clientPolicy ?? '', this.behaviourId);
                }

                if (proposal.clientQuote?.discountPriceYear?.length) {
                    this.discountRate = _.round(
                        proposal.clientQuote.discountPriceYear
                            .filter((dpy) => dpy.type === DiscountType.policyRemainingProRata)
                            .reduce((acc, cur) => acc + (cur.rate ?? 0), 0),
                        4
                    );
                }
                return forkJoin([
                    of(proposal),
                    this.quoteService.getEndorsements(this.behaviourId, proposal.insurer),
                    this.quoteService.getInsurerAssetTemplate(
                        this.behaviourId,
                        ([...(proposal?.aircraft ?? []), ...(proposal?.uavs ?? [])] as AssetProposal[])
                            .map((ap) => ap.assetTemplate)
                            .filter((at) => !!at)
                    ),
                    intermediaryUser$,
                    clientPolicy$,
                ]);
            }),
            concatMap((res) => {
                let proposal: ClientProposal;
                [proposal, this.endorsements, this.insurerAssetTemplates, this.broker, this.clientPolicy] = res;

                const model = this.mapProposal(proposal, this.endorsements, this.discountRate);
                this.model = model;
                this.transactionType = model.transactionType ? transactionTypeMapping.get(model.transactionType) : '';
                this.quoteStatus = model.clientQuote?.status ? _.capitalize(model.clientQuote?.status) : '';

                if (this.clientPolicy) {
                    return from(Auth.currentSession()).pipe(
                        concatMap((userSession) => {
                            this._sdk.rmf.accessToken = userSession.getAccessToken().getJwtToken();

                            let pilots$: Observable<Pilot[] | undefined> = of(undefined);
                            if (this.clientPolicy.pilots?.length) {
                                pilots$ = from(
                                    this._sdk.rmf.Pilots.get(this.behaviourId, {
                                        ids: this.clientPolicy.pilots,
                                        paClient: this.clientPolicy.paClient,
                                    })
                                );
                            }

                            return forkJoin([
                                from(
                                    this._sdk.rmf.Assets.get(this.behaviourId, {
                                        ids: this.clientPolicy.assetPolicies.map((ap) => ap.appliesTo.asset),
                                    })
                                ),
                                pilots$,
                            ]);
                        })
                    );
                }

                return of(undefined);
            }),
            map((res) => {
                if (res) {
                    [this.existingAssets, this.existingPilots] = res;
                }
            })
        );
    }

    mapProposal(proposal: any, endorsements: Endorsement[], discountRate: number) {
        const mappedProposal = {
            ...proposal,
        };

        if (proposal.clientQuote.additionalNotes[0]) {
            // mappedProposal.notes = proposal.clientQuote.additionalNotes[0];
            this.notes = proposal.clientQuote.additionalNotes;
        }

        this.mapClientQuotes(mappedProposal);
        this.mapPolicyEndorsements(endorsements, mappedProposal, discountRate);
        this.mapInterestedParties(mappedProposal);
        this.mapReferrals(mappedProposal);
        return mappedProposal;
    }

    mapInterestedParties(proposal) {
        proposal.clientProposalInterestedParties = proposal.interestedParties
            .filter((ip: ProposalInterestedParty) => ip.notedOn != InterestedPartyNotedOn.asset)
            .map((ip: ProposalInterestedParty) => ip.name + ' : ' + interestedPartyTypeMapping.get(ip.type));
    }

    mapClientQuotes(proposal: any) {
        if (proposal.clientQuote) {
            const aircraftQuotes = (proposal?.clientQuote?.assetQuotes as AssetQuote[])
                .filter((q) => q.assetType === assetTypes.aircraft)
                .map((assetQuote) => {
                    const aircraft = ((proposal.aircraft as AircraftProposal[]) || []).find(
                        (aircraft) => aircraft._id === assetQuote.assetProposal
                    );
                    return { ...aircraft, ...assetQuote };
                });
            proposal.clientQuote.aircraftQuotes = aircraftQuotes;
            const uavQuotes = (proposal?.clientQuote?.assetQuotes as AssetQuote[])
                .filter((q) => q.assetType === assetTypes.uav)
                .map((assetQuote) => {
                    const uav = ((proposal.uavs as AircraftProposal[]) || []).find(
                        (uav) => uav._id === assetQuote.assetProposal
                    );
                    return { ...uav, ...assetQuote };
                });
            proposal.clientQuote.uavQuotes = uavQuotes;
            const payloadQuotes = (proposal?.clientQuote?.assetQuotes as AssetQuote[])
                .filter((q) => q.assetType === assetTypes.uavPayload)
                .map((assetQuote) => {
                    const payload = ((proposal.payloads as AircraftProposal[]) || []).find(
                        (payload) => payload._id === assetQuote.assetProposal
                    );
                    return { ...payload, ...assetQuote };
                });
            proposal.clientQuote.payloadQuotes = payloadQuotes;
            const equipmentQuotes = (proposal?.clientQuote?.assetQuotes as AssetQuote[])
                .filter((q) => q.assetType === assetTypes.uavEquipment)
                .map((assetQuote) => {
                    const equipment = ((proposal.equipment as AircraftProposal[]) || []).find(
                        (equipment) => equipment._id === assetQuote.assetProposal
                    );
                    return { ...equipment, ...assetQuote };
                });
            proposal.clientQuote.equipmentQuotes = equipmentQuotes;

            proposal.clientQuote.assetQuotes = [...aircraftQuotes, ...uavQuotes, ...equipmentQuotes, ...payloadQuotes];
        }
    }

    mapPolicyEndorsements(endorsements: Endorsement[], proposal: ClientProposal, discountRate: number) {
        const { transactionType } = proposal;

        let clientProposalEndorsements = proposal.endorsements;
        if ([TransactionType.amendment, TransactionType.cancellation].includes(transactionType)) {
            clientProposalEndorsements = proposal.endorsements.filter((e) => !!e.amendType);
        }

        proposal['policyEndorsements'] =
            endorsements
                .filter(
                    (e) =>
                        e.appliesTo === EndorsementAppliesTo.policy &&
                        e.category &&
                        [EndorsementCategory.optional, EndorsementCategory.uses].includes(
                            e.category as EndorsementCategory
                        ) &&
                        !!clientProposalEndorsements.find((cpe) => cpe.endorsement === e._id)
                )
                .map((e) => ({
                    ...e,
                    amendType: (
                        clientProposalEndorsements.find((cpe) => cpe.endorsement === e._id) as ClientProposalEndorsement
                    ).amendType,
                    liabilityLimits: proposal.liabilityLimits,
                    clientQuote: proposal.clientQuote,
                    discountRate: discountRate,
                })) ?? [];
    }

    mapReferrals(proposal: any) {
        const {
            clientQuote: { assetQuotes },
        } = proposal;

        this.referrals = {
            assetReferrals:
                assetQuotes
                    .filter((aq) => aq.referrals?.length)
                    .map((aq) => {
                        let assetProposals: any[] = this.getAssetProposal(aq, proposal);
                        const assetProposal = assetProposals.find((ap: AssetProposal) => ap._id === aq.assetProposal);

                        if (!assetProposal) {
                            console.error(
                                `Expected to find an asset proposal but there was none. Proposal: ${aq.assetProposal}`
                            );
                        }

                        return {
                            description: assetProposal?.serialNumber ?? assetProposal?.description ?? '',
                            referralReasons: aq.referrals?.map((r) => r.reasonString) ?? [],
                        };
                    }) ?? [],
            policyReferrals:
                proposal.clientQuote.referrals?.map((r) => {
                    const policyReferral: PolicyReferral = {
                        description: referralTypeMapping.get(r.type) ?? r.type,
                        referralReason: r.reasonString,
                        detailedReasons: [],
                        additionalDetails: [],
                    };

                    if (r.type === ClientReferralType.hygiene && r.data?.hygiene?.length) {
                        if (typeof r.data.hygiene[0] === 'object') {
                            // remove this 'if' after HSSB work has been merged to `dev` branch
                            policyReferral.detailedReasons.push(
                                ...r.data.hygiene.map((h) => `Question: ${h.question} - Answer: ${h.answer}`)
                            );

                            //PP-3713 display Additional information
                            r.data.hygiene.forEach((h) => {
                                if (h.details?.length) {
                                    policyReferral.additionalDetails.push(`Additional information: ${h.details}`);
                                } else {
                                    policyReferral.additionalDetails.push('');
                                }
                            });
                        } else {
                            // remove this 'else' clause after HSSB work has been merged to `dev` branch
                            policyReferral.detailedReasons.push(r.data.hygiene.toString());
                        }
                    }

                    return policyReferral;
                }) ?? [],
        };
    }

    getAssetProposal(assetQuote: AssetQuote, proposal: ClientProposal) {
        let assetProposals: any[] = [];
        switch (assetQuote.assetType) {
            case assetTypes.aircraft: {
                assetProposals = proposal.aircraft as any;
                break;
            }
            case assetTypes.uav: {
                assetProposals = proposal.uavs as any;
                break;
            }
            case assetTypes.uavEquipment: {
                assetProposals = proposal.equipment as any;
                break;
            }
            case assetTypes.uavPayload: {
                assetProposals = proposal.payloads as any;
                break;
            }
            default: {
                break;
            }
        }
        return assetProposals;
    }

    openDeclineModal() {
        this.action = UnderwritingActions.decline;
        const modal = this.fields.find((fg) => fg.key === 'declineModal');
        modal?.templateOptions?.listener.next('openFromEvent');
    }

    decline() {
        this.processing = true;
        let reason = [this.model.declineModal.declineReasonSelect, this.model.declineModal.declineReasonInput]
            .filter((r) => r)
            .join(',');

        this.quoteService.declineQuote(this.proposalId, this.behaviourId, reason).subscribe(
            (data) => {
                this.notificationService.success('Declined quote successfully!');
                this.model.clientQuote.status = QuoteStatus.declined;
                this.processing = false;
                this.showUnderwritingButton = false;
                this.changeDetectorRef.detectChanges();
            },
            (error) => {
                this.notificationService.error(`Declined quote error, ${JSON.stringify(error)}`);
                this.processing = false;
            }
        );
    }

    openAcceptModal(): void {
        if (!this.isValidToAccept) {
            return;
        }
        this.action = UnderwritingActions.accept;
        this.confirmDialog.open('Please confirm you want to accept this referral!');
    }

    async accept() {
        if (!this.isValidToAccept) {
            return;
        }

        this.processing = true;

        // this.model.clientQuote.additionalNotes = [this.model.notes].filter((n) => n);
        this.model.clientQuote.additionalNotes = this.notes;
        delete this.model.clientQuote.quoteNumber;

        const clientQuote = this.cleanClientQuote(this.model.clientQuote);
        const behaviour = this.model.behaviour;

        clientQuote.assetQuotes.forEach((aq) => {
            aq.priceYearTotal = aq.priceYearArray
                ?.map((p) => p.liability ?? p.hull ?? p.standard ?? 0)
                .reduce((a, b) => a + b);
        });

        this.quoteService
            .acceptQuote(clientQuote._id, {
                clientQuote,
                behaviour,
            })
            .subscribe({
                next: () => {
                    this.notificationService.success('Accepted quote successfully!');
                    this.processing = false;
                    this.model.clientQuote.status = QuoteStatus.accepted;
                    this.showUnderwritingButton = false;
                    this.changeDetectorRef.detectChanges();
                },
                error: (error) => {
                    this.notificationService.error(`Accept quote error, ${JSON.stringify(error)}`);
                    this.processing = false;
                },
            });
    }

    private checkShowUnderwritingButton = () => {
        this.showUnderwritingButton =
            this.model?.clientQuote?.status === QuoteStatus.dirty ||
            this.model?.clientQuote?.status === QuoteStatus.filthy;
    };

    onAssetQuoteDataChange(assetModel: AssetModel) {
        const assetType = assetModel.assetType;

        const assetQuote = this.model.clientQuote.assetQuotes.find((aq) => aq._id === assetModel._id) as AssetQuote;
        const asset = this.model.clientQuote[`${assetType}Quotes`].find((aq) => aq._id === assetModel._id);

        if (assetQuote) {
            assetQuote.priceYearArray = assetModel.priceYearArray ?? [];
            assetQuote.priceYearAdjusted = assetModel.priceYearAdjusted ?? [];
            assetQuote.rateYear = assetModel.rateYear ?? null;
            assetQuote.priceYearHull = assetModel.priceYearHull ?? null;
            assetQuote.priceYearCsl = assetModel.priceYearCsl ?? null;
            assetQuote.priceYearTpl = assetModel.priceYearTpl ?? null;
            assetQuote.priceYearTotal = assetModel.priceYearArray
                ?.map((p) => p.liability ?? p.hull ?? p.standard ?? 0)
                .reduce((a, b) => a + b);
        }

        if (asset) {
            asset.priceYearArray = assetModel.priceYearArray;
            asset.priceYearAdjusted = assetModel.priceYearAdjusted;
            asset.rateYear = assetModel.rateYear;
            asset.priceYearHull = assetModel.priceYearHull;
            asset.priceYearCsl = assetModel.priceYearCsl;
            asset.priceYearTpl = assetModel.priceYearTpl;

            asset.pricesChanged = assetModel.pricesChanged;
        }
        this.model.clientQuote[`${assetType}Quotes`] = [...this.model.clientQuote[`${assetType}Quotes`]];

        const agGridField = this.getAssetGridField(assetType);
        const gridApi = agGridField?.templateOptions?.gridOptions?.api;
        gridApi.refreshCells({ force: true });
        this.updateAssetTypeTotalPremium(gridApi, assetType);
        agGridField?.formControl?.setValue(Object.assign([...this.model.clientQuote[`${assetType}Quotes`]]));
    }

    private getAssetGridField(assetType: AssetType) {
        const agGridField = this.formlyForm?.fields.find(
            (f) => f.type === 'ag-grid' && f.key === `clientQuote.${assetType}Quotes`
        );
        return agGridField;
    }

    private updateAssetTypeTotalPremium(gridApi: GridApi, assetType: AssetType) {
        const totalPremium = _.sum(
            (this.model.clientQuote.assetQuotes as AssetQuote[])
                .filter((aq) => aq.assetType === assetType && this.validateAssetQuote(aq as AssetModel))
                .map((aq) => _.sum(aq.priceYearAdjusted.map((pya) => pya.hull || pya.liability || pya.standard)))
        );

        let totalRow = {
            totalPremium: _.round(totalPremium * (1 - this.discountRate), 2),
        };
        const totalCol = this.model.transactionType === TransactionType.newBusiness ? 'serialNumber' : 'amendType';
        totalRow[totalCol] = `Total ${assetType[0].toUpperCase()}${assetType.slice(1)} Premium`;

        gridApi.setPinnedBottomRowData(totalPremium ? [totalRow] : []);
    }

    private editAsset(event: any) {
        const assetProposal = event.data;
        const clientProposal = this.model;
        const insurerAssetTemplate = this.insurerAssetTemplates?.find(
            (iat) => iat.assetTemplate === assetProposal.assetTemplate
        );

        const endorsements = getAssetEndorsements(
            this.endorsements,
            this.model?.endorsements.map((e) => e.endorsement) ?? [],
            assetProposal.uses ?? [],
            insurerAssetTemplate
        );

        endorsements.forEach((e) => {
            if (e.premiumRates?.length > 0) {
                const cpe = clientProposal.endorsements.find(
                    (endorsement: ClientProposalEndorsement) => endorsement.type === e.type
                );
                if (cpe) {
                    e.limit = cpe.limit;
                    const premiumRate = e.premiumRates.find((pr) => pr.value === cpe.limit);
                    if (premiumRate) {
                        e.premium = premiumRate?.price;
                    }
                }
            }
        });

        const assetPolicy = this.clientPolicy?.assetPolicies?.find((ap) => ap._id === assetProposal.assetPolicy);
        const existingEndorsements = getAssetEndorsements(
            this.endorsements,
            this.clientPolicy?.endorsements ?? [],
            assetPolicy?.uses ?? [],
            insurerAssetTemplate
        );
        const existingAsset = this.existingAssets?.find((a) => a._id === assetPolicy?.appliesTo.asset);
        const existingPilots = this.existingPilots
            ?.filter((p) => (assetPolicy?.pilots ?? []).includes(p._id))
            .map((p) => ({
                ...p,
                totalAircraftFlightTime: p.flightTimes?.find((ft) => ft.assetTemplate === assetProposal.assetTemplate)
                    ?.flightTime,
                totalAircraftFlightTimeLast90Days: p.flightTimesLast90Days?.find(
                    (ft) => ft.assetTemplate === assetProposal.assetTemplate
                )?.flightTime,
                totalAircraftFlightTimeLast12Months: p.flightTimesLast12Months?.find(
                    (ft) => ft.assetTemplate === assetProposal.assetTemplate
                )?.flightTime,
            }));

        const modalData = {
            ...assetProposal,
            transactionType: clientProposal.transactionType,
            currency: clientProposal.clientQuote.currency,
            locale: clientProposal.locale,
            interestedParties: clientProposal.interestedParties
                ?.filter(
                    (ip) =>
                        ip.notedOn === InterestedPartyNotedOn.asset &&
                        (assetProposal.interestedParties ?? []).includes(ip.name)
                )
                .map((ip) => ({ name: ip.name, type: ip.type })),
            pilots: clientProposal.pilots
                ?.filter((p) => (assetProposal.pilotProposals ?? []).includes(p._id))
                .map((p) => ({
                    ...p,
                    totalAircraftFlightTime: p.flightTimes?.find(
                        (ft) => ft.assetTemplate === assetProposal.assetTemplate
                    )?.flightTime,
                    totalAircraftFlightTimeLast90Days: p.flightTimesLast90Days?.find(
                        (ft) => ft.assetTemplate === assetProposal.assetTemplate
                    )?.flightTime,
                    totalAircraftFlightTimeLast12Months: p.flightTimesLast12Months?.find(
                        (ft) => ft.assetTemplate === assetProposal.assetTemplate
                    )?.flightTime,
                })),
            endorsements,
            existingAsset,
            existingEndorsements,
            existingPilots,
            insurerAssetTemplate,
            assetPolicy,
        };

        this.editAssetModal.open(modalData);
    }

    validateAssetQuote = (assetQuote: AssetModel) => {
        const insurerAssetTemplate = this.insurerAssetTemplates?.find(
            (iat) => iat.assetTemplate === assetQuote.assetTemplate
        );

        const errors = validateAssetQuote(
            assetQuote as AssetQuote,
            assetQuote as AssetProposal,
            getAssetEndorsements(
                this.endorsements,
                this.model?.endorsements.map((e) => e.endorsement) ?? [],
                assetQuote.uses ?? [],
                insurerAssetTemplate
            ),
            { endorsements: this.model.endorsements } as ClientProposal,
            this.clientPolicy?.assetPolicies?.find((ap) => ap._id === assetQuote.assetPolicy)
        );

        if (!errors.length) {
            assetQuote.missingPremiums = {};
            return true;
        }

        const missingPremiums = {};
        errors.forEach((e) => {
            const priceType = e.price ?? 'endorsement';

            missingPremiums[priceType] = true;
        });
        assetQuote.missingPremiums = missingPremiums;

        return false;
    };

    cleanClientQuote(clientQuote: any) {
        let cleanQuote = _.cloneDeep(clientQuote);
        cleanQuote.assetQuotes.forEach((aq) => {
            aq.priceYearAdjusted = filterZeroPrices(aq.priceYearAdjusted);
        });
        cleanQuote.priceYearAdjusted = filterZeroPrices(cleanQuote.priceYearAdjusted);

        return cleanQuote;
    }

    currencyValueFormatter = (params: ValueFormatterParams): string =>
        (params.value ?? '') !== '' ? formatCurrencyAmountByFormatter(params.value, this.currencyFormatter) : '';

    formatDate(date: string) {
        const utcTime = moment.utc(date);

        let localTime = utcTime.clone();
        if (this.model.timezone) {
            localTime = localTime.tz(this.model.timezone);
        }

        return localTime.format('DD MMM YYYY');
    }

    deleteNote(index: number) {
        this.notes.splice(index, 1);
    }

    editNote() {
        if (this.EditSave == 'Edit') {
            this.isNoteDisabled = false;
            this.EditSave = 'Save';
        } else {
            this.isNoteDisabled = true;
            this.EditSave = 'Edit';
        }
    }
}
