<div class="row justify-content-center">
    <form class="col-4" [formGroup]="form" (submit)="searchOrganisation()">
        <formly-form [form]="form" [model]="model" [fields]="fields" [options]="options"> </formly-form>
        <div class="row justify-content-center">
            <button
                mat-raised-button
                type="submit"
                class="mb-3 btn btn-primary"
                [disabled]="form.invalid"
                data-testid="next"
            >
                Search Organisation
            </button>
        </div>
    </form>
</div>

<div id="onboard" class="row justify-content-center">
    <button
        mat-raised-button
        class="col-md-2 col-sm-6"
        data-testid="btn-add-organisation"
        (click)="openOrganisationModal()"
    >
        Add Organisation
    </button>
    <button
        mat-raised-button
        class="col-md-2 col-sm-6"
        data-testid="btn-add-originator"
        (click)="openOriginatorModal()"
    >
        Add Broker
    </button>
</div>

<div id="organisation" class="col-md-6 col-sm-12">
    <ag-grid-angular
        class="col-12"
        domLayout="autoHeight"
        class="ag-theme-material"
        [rowData]="organisations"
        [gridOptions]="organisationGridOptions"
        (columnResized)="resizeGrids()"
    ></ag-grid-angular>
</div>

<add-organisation #organisationDialog [behaviours]="_behaviours"></add-organisation>
<add-originator #originatorDialog [behaviours]="_behaviours"></add-originator>
