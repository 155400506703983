import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CMF_ADDRESS, IDF_ADDRESS, RMF_ADDRESS } from '@pa/lib-spa';
import { map } from 'rxjs/operators';
import { Behaviour, ClientProposal, ClientQuote, IdfResponse, Originator } from '@pa/sdk/idf';
import { Observable, of } from 'rxjs';
import { CmfResponse, Endorsement, InsurerAssetTemplate } from '@pa/sdk/cmf';
import { RmfResponse, User } from '@pa/sdk/rmf';
import { isArray } from 'lodash';
import _ from 'lodash';
import { QuoteStatus } from '@pa/references/idf';

export interface savedOriginator {
    result: Originator;
    message: string;
}
@Injectable({ providedIn: 'root' })
export class QuoteService {
    behaviours: Behaviour[] = [];

    constructor(
        private http: HttpClient,
        @Inject(IDF_ADDRESS) private idfAddress: string,
        @Inject(CMF_ADDRESS) private cmfAddress: string,
        @Inject(RMF_ADDRESS) private rmfAddress: string
    ) {}

    getProposal(proposalId: string, behaviourId: string): Observable<ClientProposal> {
        return this.http
            .get<IdfResponse<ClientProposal>>(
                `${this.idfAddress}/v1/quotes/${proposalId}?behaviour=${behaviourId}&details=assets,pilots,clientQuote,originator,assetQuotes`
            )
            .pipe(map((resp) => resp.result as ClientProposal));
    }

    acceptQuote(clientQuoteId: string, payload: any) {
        payload.clientQuote = {
            ..._.pick(payload.clientQuote, ['priceYear', 'priceYearAdjusted', 'additionalNotes', 'fees']),
            assetQuotes: payload.clientQuote.assetQuotes?.map((aq) =>
                _.pick(aq, ['_id', 'priceYearArray', 'priceYearAdjusted', 'priceYearTotal'])
            ),
        };

        return this.http
            .post(`${this.idfAddress}/v1/quotes/referred/${clientQuoteId}`, payload)
            .pipe(map((resp) => resp));
    }

    declineQuote(proposalId: string, behaviour: string, declinedReason: string) {
        return this.http
            .post(`${this.idfAddress}/v1/quotes/declined/${proposalId}`, {
                behaviour,
                declinedReason,
            })
            .pipe(map((resp) => resp));
    }

    getEndorsements(behaviourId: string, insurer?: string): Observable<Endorsement[]> {
        let uriString = `${this.cmfAddress}/v1/insurerPrices/endorsements?behaviour=${behaviourId}`;
        if (insurer) {
            uriString += `&insurer=${insurer}`;
        }

        return this.http.get<CmfResponse<any>>(uriString).pipe(
            map((res) => {
                return res.result;
            })
        );
    }

    getInsurerAssetTemplate(behaviourId: string, templateId: string | string[]): Observable<InsurerAssetTemplate[]> {
        const templateIdQuery = isArray(templateId) ? templateId.join(',') : templateId;
        const uriString = `${this.cmfAddress}/v1/insurerAssetTemplates?behaviour=${behaviourId}&assetTemplate=${templateIdQuery}`;

        return this.http.get<CmfResponse<any[]>>(uriString).pipe(
            map((res) => {
                return res.result;
            })
        );
    }

    getBehaviour(behaviourId = '', insurer = '', product = ''): Observable<Behaviour> {
        return this.http
            .get<IdfResponse<Behaviour>>(
                `${this.idfAddress}/v1/behaviours/?behaviourId=${behaviourId}&insurer=${insurer}&product=${product}`
            )
            .pipe(
                map((res) => {
                    return res.result[0];
                })
            );
    }

    getBehavioursfromCognito(): Observable<Behaviour[]> {
        if (this.behaviours.length) {
            return of(this.behaviours);
        }
        return this.http
            .get<IdfResponse<any>>(`${this.idfAddress}/v1/behaviours/underwriter`)
            .pipe(
                map((res) => {
                    this.behaviours = res.result;
                    return res.result;
                })
            );
    }

    getUser(behaviourId: string, userId: string): Observable<User> {
        const uriString = `${this.rmfAddress}/v1/users/${userId}?behaviour=${behaviourId}&isOriginator=true`;

        return this.http.get<RmfResponse<User>>(uriString).pipe(
            map((res) => {
                return res.result;
            })
        );
    }

    getQuote(proposalId: string, behaviourId: string) {
        return this.http
            .get<IdfResponse<ClientQuote>>(`${this.idfAddress}/v1/clientQuotes?clientProposal=${proposalId}&behaviour=${behaviourId}`)
            .pipe(map((resp) => resp.result as ClientQuote[]));
    }

    getESQuotes(config: {
        quoteStatus?: QuoteStatus | "none",
        createdDateFrom?: string,
        createdDateTo?: string,
        companyName?: string,
        behaviourId?: string
    }): Observable<any> {
        let query = "";
        Object.keys(config).forEach((key, index) => {
            if (index === 0) {
                query += `?${key}=${config[key]}`
            } else {
                query += `&${key}=${config[key]}`
            }
        });

        return this.http
            .get<any>(`${this.idfAddress}/v1/elasticsearch/quote${query}`)
            .pipe(map((res) => res.result));
    }

    getESOrgs(companyName: string): Observable<any> {
        return this.http
            .get<any>(`${this.idfAddress}/v1/elasticsearch/organisation?companyName=${companyName}`)
            .pipe(map((res) => res.result));
    }
}