import _ from 'lodash';
import { EndorsementType, PriceType } from '@pa/references/idf';
import { Endorsement } from '@pa/sdk/cmf';
import { QuotePriceInput } from '@pa/sdk/idf';
import { QuotePrice } from '@pa/sdk/idf/src/types/common';
import { isPinnedBottom } from './ag-grid';

export const getEndorsementLimit = (endorsement: Endorsement, premises?: number, publicLiab?: number): number => {
    let limit: number = 0;

    if (endorsement.type === EndorsementType.prl) {
        limit = endorsement.premiumRates?.find((pr) => pr.value === premises)?.value ?? limit;
    }
    if (endorsement.type === EndorsementType.pbl) {
        limit = endorsement.premiumRates?.find((pr) => pr.value === publicLiab)?.value ?? limit;
    } else {
        limit = endorsement.limit ?? limit;
    }

    return _.round(limit, 2);
};

export const getEndorsementPrice = (
    endorsement: Endorsement,
    premises?: number,
    publicLiab?: number,
    rowParams?: any
): number => {
    if (isPinnedBottom(rowParams)) {
        return;
    }
    let price: number = 0;

    if (endorsement.type === EndorsementType.prl) {
        price = endorsement.premiumRates?.find((pr) => pr.value === premises)?.price ?? price;
    }
    if (endorsement.type === EndorsementType.pbl) {
        price = endorsement.premiumRates?.find((pr) => pr.value === publicLiab)?.price ?? price;
    } else {
        price = endorsement.premium ?? price;
    }

    return _.round(price, 2);
};

export const saveEndorsementPriceYear = (priceYear: QuotePriceInput, value?: number): void => {
    const liabilityTypes: PriceType[] = [PriceType.avn57, PriceType.prl, PriceType.pbl];

    if (liabilityTypes.includes(priceYear.type)) {
        priceYear.liability = value;
    } else {
        priceYear.standard = value;
    }
};

/**
 * Remove zero price components
 * @param prices List of prices
 */
export function filterZeroPrices(prices: QuotePrice[]): QuotePrice[] {
    // Decided to use Math.abs() > 0.01 to handle cases where javascript handles floats poorly
    return (prices || []).filter((p) => Math.abs((p.hull || 0) + (p.liability || 0) + (p.standard || 0)) > 0.01);
}
