import { AmendType, TransactionType } from '@pa/references/idf';
import { ClientPolicy, Endorsement } from '@pa/sdk/cmf';
import { QuotePriceInput } from '@pa/sdk/idf';
import { ValueSetterParams } from 'ag-grid-community';
import { round } from 'lodash';

import { adjustPrices } from '../edit-asset/common/utils';
import { saveEndorsementPriceYear } from './endorsements';

export function setEndorsementPrice(
    transactionType: TransactionType,
    type: any,
    premium: number,
    quotePriceYear: QuotePriceInput[],
    quotePriceYearAdjusted: QuotePriceInput[],
    policyPriceYear?: QuotePriceInput[],
    amendType?: AmendType
) {
    const roundedPremium = round(premium, 2);

    let price: QuotePriceInput | undefined = quotePriceYear?.find((pya) => pya.type === type);
    if (!price) {
        price = { type };
        saveEndorsementPriceYear(price, roundedPremium);
        quotePriceYear.push(price);
    } else {
        saveEndorsementPriceYear(price, roundedPremium);
    }

    let priceAdjusted: QuotePriceInput | undefined = quotePriceYearAdjusted.find((pya) => pya.type === type);
    if (!priceAdjusted) {
        priceAdjusted = { type };
        saveEndorsementPriceYear(priceAdjusted, roundedPremium);
        quotePriceYearAdjusted.push(priceAdjusted);
    }

    const isAmendment = [TransactionType.amendment, TransactionType.cancellation].includes(transactionType);
    const existingPrice = isAmendment ? policyPriceYear?.find((pya) => pya.type === type) : undefined;
    Object.assign(priceAdjusted, adjustPrices(price, existingPrice ?? { type }, undefined, amendType));
}

export const policyEndorsementPremiumValueSetter =
    (transactionType: TransactionType, clientPolicy?: ClientPolicy) =>
    (params: ValueSetterParams): boolean => {
        const { type, amendType } = params.data;

        const premium = parseFloat(params.newValue);
        if (!isFinite(premium)) {
            return false;
        }

        setEndorsementPrice(
            transactionType,
            type,
            premium,
            params.data.clientQuote.priceYear ?? [],
            params.data.clientQuote.priceYearAdjusted ?? [],
            clientPolicy?.priceYear,
            amendType
        );
        let rows = (params.context?.parentField?.fieldGroup || []).map((fg) => fg.formControl.value) as Endorsement[];

        params.api?.setRowData(rows);
        return true;
    };
