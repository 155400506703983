import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviourService, mapAttachments } from '@pa/lib-spa';
import { Attachment } from '@pa/lib-spa/types';
import { originatorTypes, TransactionType } from '@pa/references/idf';
import { Behaviour, ClientProposal, ClientQuote, Originator } from '@pa/sdk/idf';
import { formatDate } from '../utils/date';
import { switchMap } from 'rxjs/operators';
import { mapDocumentationLinks } from '../core/utils/attachment.mapping';
import { PolicyService } from '../services/policy.service';
import { QuoteService } from '../services/quote.service';

@Component({
  selector: 'app-view-quote',
  templateUrl: './view-quote.component.html',
  styleUrls: ['./view-quote.component.scss']
})
export class ViewQuoteComponent implements OnInit {
  attachments: Attachment[];
  behaviour: Behaviour;
  behaviourId: string = '';
  clientProposal: ClientProposal;
  clientQuote: ClientQuote;
  error: boolean = false;
  isNB: boolean = false;
  isReferral: string = 'No';
  loading: boolean = true;
  originator: Originator;
  policyNumber: string;
  proposedEffectiveDate: string;
  proposalId: string = '';
  referralPageLink: string = '';
  showBroker: boolean = false;
  formatDate = formatDate;

  constructor(
    private router: ActivatedRoute,
    private quoteService: QuoteService,
    private behaviourService: BehaviourService,
    private policyService: PolicyService
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.router.queryParams.pipe(
      switchMap((params) => {
        this.proposalId = params.proposalId;
        this.behaviourId = params.behaviourId;

        return this.quoteService.getBehaviour(this.behaviourId);
      }),
      switchMap((behaviour) => {
        this.behaviour = behaviour;
        this.behaviourService.set(behaviour);

        return this.quoteService.getProposal(this.proposalId, this.behaviourId);
      })
    ).subscribe({
      next: async (proposal) => {
        this.clientProposal = proposal;
        this.clientQuote = proposal.clientQuote;
        this.originator = this.clientProposal.originator as Originator;

        this.isNB = (this.clientProposal.transactionType === TransactionType.newBusiness);
        this.showBroker = (this.originator.type === originatorTypes.intermediary);
        this.isReferral = (this.clientQuote.referrals?.length > 0) ? "Yes" : "No";
        
        this.proposedEffectiveDate = this._getProposedEffectiveDate(this.clientProposal);
        const documentlinks: Attachment[] = mapDocumentationLinks(this.clientProposal.documentationLinks);
        this.attachments = mapAttachments(documentlinks, this.behaviour, TransactionType.amendment);

        if (this.clientProposal.clientPolicy) {
          const res = await this.policyService.getPolicyHistory(this.clientProposal.clientPolicy, this.behaviourId).toPromise();
          this.policyNumber = res?.reference;
          if (res?.latestRevision) {
            this.policyNumber += ` Revision ${res.latestRevision}`;
          }
        }

        this.referralPageLink = `underwriting?proposalId=${this.proposalId}&behaviourId=${this.behaviourId}`;

        this.loading = false;
        this.error = false;
      },
      error: (err) => {
        this.loading = false;
        this.error = true;
      }
    })
  }

  formatNameFromEmail(name: string): string {
    return (name.split('@'))[0];
  }

  private _getProposedEffectiveDate(clientProposal: ClientProposal): string {
    switch (clientProposal.transactionType) {
      case TransactionType.newBusiness:
        return clientProposal.inceptionDate;
      case TransactionType.amendment:
        return clientProposal.amendmentEffectiveDate;
      case TransactionType.renewal:
        return clientProposal.renewalEffectiveDate;
      case TransactionType.cancellation:
        return clientProposal.cancellationEffectiveDate;
      default:
        //should never come here
        return "";
    }
  }

}
