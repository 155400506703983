import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthState } from '@aws-amplify/ui-components';

import { AuthService } from './auth.service';
import { map, skipWhile } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private _authService: AuthService, private router: Router) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this._authService.authState.pipe(
            // NOTE: Skip the default state, i.e. user hasn't tried to login yet
            skipWhile(state => !state || state === AuthState.SignIn),
            map(state => state === AuthState.SignedIn)
        );
    }
}
