import { Component, Input, OnInit } from '@angular/core';

import { pick } from 'lodash';

import { BehaviourService } from '@pa/lib-spa';
import { ExperienceType } from '@pa/references';
import {
    CoverageType,
    CoverageTypeDisplay,
    CurrencyCode,
    formatCurrencyAmountByFormatter,
    getCurrencyFormatter,
    openPilotWarranties,
    TransactionType,
} from '@pa/references/idf';
import { displayIsoCode, iso3166 } from '@pa/references/iso3166';
import { AssetType, assetTypes, OperationType, operationTypeDisplayMap } from '@pa/references/paul-precision';
import { AssetProposal, BehaviourUiConfig, HangarAddress } from '@pa/sdk/idf';
import { OpwExperience } from '@pa/sdk/idf/dist/cmf/src/types';
import { InsurerAssetTemplate } from '@pa/sdk/cmf';
import { Asset } from '@pa/sdk/rmf';

import { AssetModel } from '../../types';

interface InfoDisplay {
    amendType?: string;
    model?: string;
    registration?: string;
    serialNumber?: string;
    location?: string;
    coverageType?: string;
    agreedValue?: string;
    opw?: string;
    uses?: string[];
    operations?: string[];
    passengerSeats?: number;
    crewSeats?: number;
}

export const mapCoverageType = (coverageType: CoverageType, uiConfig: BehaviourUiConfig | undefined) => {
    if (uiConfig && uiConfig.coverageTypes) {
        const coverage = uiConfig.coverageTypes.find((ct) => ct.type === coverageType);
        if (coverage) {
            return coverage.display;
        }
    }
    return CoverageTypeDisplay[coverageType];
};

type AssetDisplayModelFields = Pick<
    AssetProposal,
    'type' | 'manufacturer' | 'model' | 'uavModel' | 'yearOfManufacture'
>;

@Component({
    selector: 'asset-basic-info',
    templateUrl: './basic-info.component.html',
    styleUrls: ['./basic-info.component.scss'],
})
export class BasicInfoComponent implements OnInit {
    @Input() asset: AssetModel;

    public assetType: AssetType | undefined;
    public info: InfoDisplay;
    public policyInfo: InfoDisplay | undefined;
    public showAmendType: boolean;
    public showExistingPolicy = false;

    public currencyFormatter: Intl.NumberFormat = getCurrencyFormatter(CurrencyCode.AUD);

    constructor(private behaviourService: BehaviourService) { }

    ngOnInit() {
        this.assetType = this.asset.assetType;
        this.showAmendType = this.asset.transactionType !== TransactionType.newBusiness;
        this.showExistingPolicy = !!this.asset.assetPolicy && !!this.asset.existingAsset;

        this.behaviourService.behaviour.subscribe((b) => {
            const assetPolicy = this.asset.assetPolicy;
            const assetType = this.assetType;
            const currency = this.asset.currency;
            const existingAsset = this.asset.existingAsset;
            const insurerAssetTemplate = this.asset.insurerAssetTemplate;
            const opwExperience = this.asset.opwExperience;

            if (this.showExistingPolicy) {
                const manufacturer = existingAsset.manufacturer ?? '';
                const model = existingAsset.model ?? '';

                this.policyInfo = {
                    agreedValue: this._displayAgreedValue(currency, assetPolicy.agreedValue),
                    coverageType: this._displayCoverageType(assetType, 0, assetPolicy.coverageType, b.uiConfig),
                    crewSeats: this._displayCrewSeats(assetType, existingAsset?.crewSeats, b.uiConfig),
                    location: this._displayLocation(assetPolicy.hangarAddress),
                    model: this._displayModel({
                        type: assetPolicy.appliesTo.type,
                        manufacturer: manufacturer,
                        model: model,
                        yearOfManufacture: existingAsset.yearOfManufacture ?? '',
                    } as Asset),
                    operations: this._displayOperations(assetPolicy.operations),
                    opw: this._displayOpw(manufacturer, model, opwExperience, insurerAssetTemplate, assetPolicy.opw),
                    passengerSeats: this._displayPassengerSeats(assetType, existingAsset?.passengerSeats, b.uiConfig),
                    registration: this._displayRegistration(existingAsset?.serialNumber, assetPolicy.registrationCountry),
                    serialNumber: this._displayAircraftSerialNumber(assetType, existingAsset?.aircraftSerialNumber, b.uiConfig),
                    uses: this._displayUses(insurerAssetTemplate, assetPolicy.uses),
                };
            }

            this.info = {
                amendType: this.asset.amendType,

                agreedValue: this._displayAgreedValue(currency, this.asset.agreedValue ?? 0),
                coverageType: this._displayCoverageType(
                    assetType,
                    this.asset.agreedValue ?? 0,
                    this.asset.coverageType,
                    b.uiConfig
                ),
                crewSeats: this._displayCrewSeats(assetType, this.asset.crewSeats, b.uiConfig),
                location: this._displayLocation(this.asset.hangarAddress),
                model: this._displayModel({
                    ...pick(this.asset, ['manufacturer', 'model', 'uavModel', 'yearOfManufacture']),
                    type: this.assetType,
                }),
                operations: this._displayOperations(this.asset.operations),
                opw: this._displayOpw(
                    this.asset.manufacturer ?? '',
                    this.asset.model ?? '',
                    opwExperience,
                    insurerAssetTemplate,
                    this.asset.opw,
                ),
                passengerSeats: this._displayPassengerSeats(assetType, this.asset.passengerSeats, b.uiConfig),
                registration: this._displayRegistration(this.asset.serialNumber, this.asset.registrationCountry),
                serialNumber: this._displayAircraftSerialNumber(assetType, this.asset.aircraftSerialNumber, b.uiConfig),
                uses: this._displayUses(insurerAssetTemplate, this.asset.uses),
            };
        });
    }

    infoTableClass() {
        return this.showExistingPolicy ? 'col-md-6' : 'col-md-12';
    }

    private _displayAgreedValue(currency: CurrencyCode, agreedValue: number) {
        const currencyFormatter = getCurrencyFormatter(currency);

        if (agreedValue > 0) {
            return formatCurrencyAmountByFormatter(agreedValue, currencyFormatter);
        }
    }

    private _displayAircraftSerialNumber = (
        assetType: AssetType,
        aircraftSerialNumber?: string,
        uiConfig?: BehaviourUiConfig
    ): string | undefined => {
        if (assetType === assetTypes.aircraft && uiConfig?.aircraftSerialNumber) {
            return aircraftSerialNumber;
        }
    };

    private _displayCoverageType(
        assetType: AssetType,
        agreedValue?: number,
        coverageType?: CoverageType,
        uiConfig?: BehaviourUiConfig
    ) {
        if (coverageType) {
            return mapCoverageType(coverageType, uiConfig);
        } else if (assetType === assetTypes.uav) {
            return agreedValue ? 'Hull and Liability' : 'Liability Only';
        }
    }

    private _displayCrewSeats = (
        assetType: AssetType,
        crewSeats?: number,
        uiConfig?: BehaviourUiConfig
    ): number | undefined => {
        if (assetType === assetTypes.aircraft && uiConfig?.passengerLiab) {
            return crewSeats;
        }
    };

    private _displayLocation(hangarAddress?: HangarAddress) {
        if (hangarAddress?.subdivision) {
            return [displayIsoCode(hangarAddress.subdivision), hangarAddress.postalCode].filter((v) => !!v).join(', ');
        }
    }

    private _displayModel(asset: AssetDisplayModelFields) {
        if (asset.type === assetTypes.uavEquipment || asset.type === assetTypes.uavPayload) {
            return;
        }
        let modelString = `${asset.yearOfManufacture}, ${asset.manufacturer}`;

        modelString += asset.type === assetTypes.uav ? ` ${asset.uavModel}` : ` ${asset.model}`;

        return modelString;
    }

    private _displayOperations = (operations?: OperationType[]): string[] | undefined => {
        if (operations?.length) {
            return operations.map((o) => operationTypeDisplayMap[o]);
        }
    };

    private _displayOpw = (
        manufacturer: string,
        model: string,
        opwExperience: OpwExperience[],
        insurerAssetTemplate?: InsurerAssetTemplate,
        opw?: String,
    ): string | undefined => {
        let opwConfig = insurerAssetTemplate?.opw?.find((o) => o._id === opw) ?? {
            _id: null,
            type: null
        };

        if (opwExperience) {
            opwConfig["flightTimeTotal"] = (opwExperience.find(exp => exp.type === ExperienceType.journeyTimeTotal))?.journeyTime ?? 0;
            opwConfig["flightTimeAsset"] = (opwExperience.find(exp => exp.type === ExperienceType.journeyTimeAsset))?.journeyTime ?? 0;
        }

        if (opwConfig.type !== openPilotWarranties.namedPilot && !(opwConfig.flightTimeTotal || opwConfig.flightTimeAsset)) return;

        const { type, flightTimeTotal, flightTimeAsset } = opwConfig;

        if (type === openPilotWarranties.namedPilot) {
            return 'Named Pilot Only';
        }

        return `${flightTimeTotal} hours Total Time, ${flightTimeAsset} hours on ${manufacturer} ${model}`;
    };

    private _displayPassengerSeats = (
        assetType: AssetType,
        passengerSeats?: number,
        uiConfig?: BehaviourUiConfig
    ): number | undefined => {
        if (assetType === assetTypes.aircraft && uiConfig?.passengerLiab) {
            return passengerSeats;
        }
    };

    private _displayRegistration(serialNumber?: string, registrationCountry?: string) {
        let registration = '';
        if (registrationCountry) {
            registration += `${iso3166.find((country) => country.code === registrationCountry)?.name}, `;
        }

        if (serialNumber) {
            registration += serialNumber;
            return registration;
        }
    }

    private _displayUses(insurerAssetTemplate?: InsurerAssetTemplate, uses?: string[]) {
        if (uses?.length) {
            return uses.map((useId) => insurerAssetTemplate?.uses?.find((u) => u._id === useId)?.type.toString() ?? '');
        }
    }
}
