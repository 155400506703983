import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

declare const hbspt: any

@Component({
    selector: 'cp-dashboard',
    templateUrl: './dashboard.page.html'
})
export class DashboardPage implements AfterViewInit, OnInit {
    public showForms: Observable<boolean>;

    private _addAssetForm: any;
    private _addBrokerForm: any;

    constructor(private _authService: AuthService) {}

    ngOnInit() {
        this.showForms = this._authService.userGroups.pipe(
            map(groups => {
                return groups.includes('hssb');
            })
        )
    }

    ngAfterViewInit() {
        if (!this._addAssetForm) {
            this._addAssetForm = hbspt.forms.create({
                region: 'na1',
                portalId: '5189173',
                formId: 'ac24253d-2752-4f91-9883-a5ae206a3ba0',
                target: '#addAssetsForm'
            });
        }

        if (!this._addBrokerForm) {
            this._addBrokerForm = hbspt.forms.create({
                region: 'na1',
                portalId: '5189173',
                formId: '7f93fb4d-1ba1-4699-9f6d-2429dcbd36dd',
                target: '#addBrokerForm'
            });
        }
    }
}
