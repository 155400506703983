<div>
    <h1>Add Asset Template</h1>
    <mat-card class="mb-3">
        <mat-card-header>
            <mat-card-title>Template Configuration</mat-card-title>
        </mat-card-header>
        <mat-card-content>
            <div class="row justify-content-center" *ngIf="loadingForm">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <form [formGroup]="form" *ngIf="!loadingForm">
                <div *ngIf="fields.length === 0">Enter a valid behaviour query</div>
                <formly-form [form]="form" [model]="model" [fields]="fields"></formly-form>
                <div class="row justify-content-center pb-5" *ngIf="fields.length > 0">
                    <button
                        [disabled]="!form.valid"
                        mat-raised-button
                        type="submit"
                        class="ml-2 btn btn-primary"
                        (click)="openSummaryDialog()"
                        data-testid="open-summary-modal"
                    >
                        Add {{assetDisplay}}
                    </button>
                </div>
            </form>
        </mat-card-content>
    </mat-card>
</div>

<ng-template #summaryDialog>
    <mat-dialog-content [style.minWidth.px]="500">
        <div data-testid="accept-dialog">
            <h1>New {{assetDisplay}} Summary</h1>

            <table class="mat-table w-100">
                <tr class="mat-row">
                    <th class="mat-header-cell w-25">Manufacturer:</th>
                    <td class="mat-cell">
                        {{ model.manufacturer }}
                    </td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Model:</th>
                    <td class="mat-cell">
                        {{ model.model }}
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="!!model.gearType">
                    <th class="mat-header-cell">Gear Type:</th>
                    <td class="mat-cell">
                        {{ gearTypeDisplay[model.gearType] }}
                    </td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Lift Type:</th>
                    <td class="mat-cell">
                        {{ liftTypeDisplay[model.liftType] }}
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="!!model.defaultCrewSeats && !!model.defaultPaxSeats">
                    <th class="mat-header-cell">Seats</th>
                    <td class="mat-cell">
                        Crew: {{ model.defaultCrewSeats }}
                    </td>
                    <td class="mat-cell">
                        Passenger: {{ model.defaultPaxSeats }}
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="!!model.weight">
                    <th class="mat-header-cell">Weight:</th>
                    <td class="mat-cell">
                        {{ model.weight }} g
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="model.mountable !== undefined && model.mountable !== null">
                    <th class="mat-header-cell">Mountable:</th>
                    <td class="mat-cell">
                        {{ booleanDisplay(model.mountable) }}
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="!!model.assetReference">
                    <th class="mat-header-cell">Asset/Model Reference:</th>
                    <td class="mat-cell">
                        {{ model.assetReference }}
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="!!model.categoryCode">
                    <th class="mat-header-cell">Category Code:</th>
                    <td class="mat-cell">
                        {{ model.categoryCode }}
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="!!model.referralMaxMtom && !!model.referralMinMtom">
                    <th class="mat-header-cell">MTOM Referral</th>
                    <td class="mat-cell">
                        Max: {{ model.referralMaxMtom }}
                    </td>
                    <td class="mat-cell">
                        Min: {{ model.referralMinMtom }}
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="!!model.referralYearOfManufactureFrom && !!model.referralYearOfManufactureTo">
                    <th class="mat-header-cell">Year of Manufacture Referral</th>
                    <td class="mat-cell">
                        From: {{ model.referralYearOfManufactureFrom }}
                    </td>
                    <td class="mat-cell">
                        To: {{ model.referralYearOfManufactureTo }}
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="!!model.referralMaxSeats && !!model.referralMinSeats">
                    <th class="mat-header-cell">Seats Referral</th>
                    <td class="mat-cell">
                        Max: {{ model.referralMaxSeats }}
                    </td>
                    <td class="mat-cell">
                        Min: {{ model.referralMinSeats }}
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="!!model.referralAge">
                    <th class="mat-header-cell">Age Referral:</th>
                    <td class="mat-cell">
                        {{ model.referralAge }}
                    </td>
                </tr>
                <tr class="mat-row" *ngIf="!!model.referralMaxValue">
                    <th class="mat-header-cell">Max Value Referral:</th>
                    <td class="mat-cell">
                        {{ model.referralMaxValue }}
                    </td>
                </tr>
                <tr class="mat-row">
                    <th class="mat-header-cell">Always Refer:</th>
                    <td class="mat-cell">
                        {{ booleanDisplay(model.referAlways) }}
                    </td>
                </tr>
            </table>
            <div class="row justify-content-center" *ngIf="processingSubmission">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="text-center mt-4 mb-1" *ngIf="!processingSubmission">
                <button
                    mat-raised-button
                    type="button"
                    (click)="closeSummaryDialog()"
                    data-testid="summary-dialog-close-button"
                >
                    Close
                </button>
                <button
                    mat-raised-button
                    class="ml-2 btn btn-primary"
                    type="submit"
                    cdkFocusInitial
                    (click)="submit()"
                    data-testid="summary-dialog-submit-button"
                >
                    Submit
                </button>
            </div>
        </div>
    </mat-dialog-content>
</ng-template>
