import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { forkJoin, of, throwError } from 'rxjs';
import { concatMap } from 'rxjs/operators';

import { TemplateType } from '@pa/references/idf';
import { BehaviourService } from '@pa/lib-spa';
import { Behaviour } from '@pa/sdk/idf';

import { AuthService } from '../core/auth/auth.service';
import { ClaimsService } from './claims.service';
import { PolicyService } from '../services/policy.service';
import { formatDate } from '../utils/temporal';

// NOTE: Copied from ServicePortal. Consider moving to @pa/lib-spa as a util
const getFileTemplateType = (filename: string, behaviour: Behaviour) =>
    behaviour.documentFilenames
        // Need to sort docs with 'wording' to the bottom so 'policy' and 'quote' are found first
        .sort((file) => (file.output.includes('wording') ? -1 : 1))
        .find((file) => filename.includes(file.output))?.type;

interface BasicInfo {
    companyName: string;
    dateOfLoss: string;
    documents: { display: string; url: string }[];
    expiryDate: string;
    reference: string;
    renewalDate: string;
}
@Component({
    selector: 'cp-claims',
    templateUrl: './claims.page.html',
})
export class ClaimsPage implements OnInit {
    public basicInfo: BasicInfo;
    public loading = true;

    private _incidentReportId: string;
    private _behaviourId: string;

    constructor(
        private _router: ActivatedRoute,
        private _authService: AuthService,
        private _behaviourService: BehaviourService,
        private _claimsService: ClaimsService,
        private _policiesService: PolicyService
    ) {}

    ngOnInit() {
        this._router.queryParams
            .pipe(
                concatMap((params) => {
                    const { behaviour, incidentReport } = params;

                    if (behaviour && incidentReport) {
                        return forkJoin([
                            this._behaviourService.getAndSetNewBehaviour(behaviour),
                            this._claimsService.getIncidentReport(behaviour, incidentReport),
                        ]);
                    }

                    return throwError(new Error('Expected a behaviour and an incident report id'));
                }),
                concatMap(([b, ir]) => {
                    return forkJoin([of(b), of(ir), this._policiesService.getPolicy(ir.clientPolicy, b._id)]);
                }),
                concatMap(([b, ir, cp]) => {
                    return forkJoin([of(b), of(ir), of(cp), this._claimsService.getClient(b._id, cp.paClient)]);
                })
            )
            .subscribe({
                next: ([behaviour, incidentReport, clientPolicy, paClient]) => {
                    const documents = (incidentReport as any).documentationLinks.map((d) => {
                        const templateType = getFileTemplateType(d.filename, behaviour);

                        switch (templateType) {
                            case TemplateType.incidentReport: {
                                return { display: 'Incident Report', url: d.url };
                            }
                            default: {
                                return { display: d.filename, url: d.url };
                            }
                        }
                    });
                    const timezone = clientPolicy.timezone;

                    this.basicInfo = {
                        companyName: paClient.companyName,
                        dateOfLoss: formatDate(incidentReport.dateOfLoss, timezone),
                        documents,
                        expiryDate: formatDate(
                            moment.utc(clientPolicy.expiryDate).subtract(1, 'd').toISOString(),
                            timezone
                        ),
                        reference: clientPolicy.reference,
                        renewalDate: formatDate(clientPolicy.renewalDate ?? clientPolicy.inceptionDate, timezone),
                    };

                    this.loading = false;
                },
                error: (err) => {
                    this.loading = false;
                    console.error(err);
                },
            });
    }
}
