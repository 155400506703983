<!-- Title -->
<div id="title-bar">
    <span class="col-md-2">
        <a data-testid="back-to-search-results" routerLink="/quotePolicyHome">
            << Back to search results </a>
    </span>
    <span class="col-md-8">
        <h1>View Policy</h1>
    </span>
    <span class="col-md-2" hidden></span>
</div>

<!-- Loading Spinner -->
<div *ngIf="loading && !error" class="row justify-content-center my-3">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

<div *ngIf="!loading && !error">
    <mat-card class="my-3">
        <div class="row">
            <div class="col-sm-6">
                <mat-card-header>
                    <mat-card-title>Policy Details</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table class="mat-table">
                        <tr>
                            <th class="mat-header-cell">Insured Name</th>
                            <td class="mat-cell" data-testid="InsuredName">{{ paClient.companyName}}</td>
                        </tr>
                        <tr>
                            <th class="mat-header-cell">Policy Number</th>
                            <td class="mat-cell" data-testid="policyNumber">{{clientPolicy.reference}}
                                <span *ngIf="clientPolicy.referenceRevision">
                                    Revision {{ clientPolicy.referenceRevision }}
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="showInceptionDate">
                            <th class="mat-header-cell">Inception Date</th>
                            <td class="mat-cell" data-testid="inceptionDate">{{ formatDate(clientPolicy.inceptionDate) }}</td>
                        </tr>
                        <tr *ngIf="!showInceptionDate">
                            <th class="mat-header-cell">Renewal Date</th>
                            <td class="mat-cell" data-testid="renewalDate">{{ formatDate(clientPolicy.renewalDate) }}</td>
                        </tr>
                        <tr *ngIf="clientPolicy.amendedDate">
                            <th class="mat-header-cell">Amendment effective date</th>
                            <td class="mat-cell" data-testid="amendedDate">{{ formatDate(clientPolicy.amendedDate) }}</td>
                        </tr>
                        <tr>
                            <th class="mat-header-cell">Expiry Date</th>
                            <td class="mat-cell" data-testid="expiryDate">{{ formatDate(clientPolicy.expiryDate) }}</td>
                        </tr>
                        <tr *ngIf="clientPolicy.interestedParties.length">
                            <th class="mat-header-cell">Policy-Wide Interested Parties</th>
                            <td class="mat-cell">
                                <mat-list-item *ngFor="let ip of clientPolicy.interestedParties">{{ ip.name }}
                                </mat-list-item>
                            </td>
                        </tr>
                    </table>
                </mat-card-content>
            </div>
            <div class="col-sm-6">
                <mat-card-header>
                    <mat-card-title>Policy Documentation Links</mat-card-title>
                </mat-card-header>
                <mat-card-content>
                    <table>
                        <li *ngFor="let attachment of attachments">
                            <a [href]="attachment.url" target="_blank"> {{ attachment.fileName }}</a>
                        </li>
                    </table>
                </mat-card-content>
            </div>
        </div>
    </mat-card>
</div>