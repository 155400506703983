import Amplify from 'aws-amplify';
import { AgGridModule } from 'ag-grid-angular';
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { AmplifyUIAngularModule } from '@aws-amplify/ui-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';

import {
    CMF_ADDRESS,
    FormComponentsModule,
    IDF_ADDRESS,
    RMF_ADDRESS,
    OriginatorModule,
    HeaderFooterModule,
} from '@pa/lib-spa';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { DashboardPage } from './core/dashboard/dashboard.page';
import { TokenInterceptor } from './core/interceptor/TokenInterceptor';
import { NavigationComponent } from './core/navigation/navigation.component';
import { ClaimsModule } from './claims/claims.module';
import { ClientQuoteComponent } from './client-quotes/client-quote.component';
import { FormComponentsModule as DomesticFormComponentModule } from './components/form/form-components.module';
import { MaterialModule } from './material-module';
import { RateManagementModule } from './rate-management/rate-management.module';
import { EditAssetModalComponent } from './underwriting/edit-asset/asset.modal';
import { AircraftInfoComponent } from './underwriting/edit-asset/aircraft/aircraft-info.component';
import { ConfirmDialogComponent } from './components/dialogs/confirm-dialog.component';
import { BasicInfoComponent } from './underwriting/edit-asset/common/basic-info/basic-info.component';
import { PremiumComponent } from './underwriting/edit-asset/common/premium/premium.component';
import { EndorsementsComponent } from './underwriting/edit-asset/common/endorsements/endorsements.component';
import { EquipmentInfoComponent } from './underwriting/edit-asset/equipment/equipment-info.component';
import { PayloadInfoComponent } from './underwriting/edit-asset/payloads/payload-info.component';
import { UavInfoComponent } from './underwriting/edit-asset/uavs/uav-info.component';
import { UnderwritingComponent } from './underwriting/underwriting.component';
import { DistributionManagementComponent } from './distribution-management/distribution-management.component';
import { ViewOrganisationComponent } from './view-organisation/view-organisation.component';
import { AddOrganisation } from './distribution-management/dialog/add-organisation/add-organisation';
import { AddOriginator } from './distribution-management/dialog/add-originator/add-originator';
import { BehaviourSelectionComponent } from './components/behaviour-selection/behaviour-selection.component';
import { ViewQuoteComponent } from './view-quote/view-quote.component';
import { ViewPolicyComponent } from './view-policy/view-policy.component';
import { QuotePolicyHomePageComponent } from './quote-policy-home-page/quote-policy-home-page.component';

Amplify.configure(environment.amplify);

@NgModule({
    declarations: [
        AircraftInfoComponent,
        AppComponent,
        BasicInfoComponent,
        ConfirmDialogComponent,
        DashboardPage,
        EditAssetModalComponent,
        EndorsementsComponent,
        EquipmentInfoComponent,
        NavigationComponent,
        PayloadInfoComponent,
        PremiumComponent,
        UavInfoComponent,
        UnderwritingComponent,
        ClientQuoteComponent,
        DistributionManagementComponent,
        ViewOrganisationComponent,
        AddOrganisation,
        AddOriginator,
        BehaviourSelectionComponent,
        ViewQuoteComponent,
        ViewPolicyComponent,
        QuotePolicyHomePageComponent,
    ],
    imports: [
        AgGridModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        ClaimsModule,
        LayoutModule,
        MaterialModule,
        AmplifyUIAngularModule,
        NgxSpinnerModule,
        HttpClientModule,
        OriginatorModule,
        FormComponentsModule,
        RateManagementModule,
        ReactiveFormsModule,
        DomesticFormComponentModule,
        HeaderFooterModule,
    ],
    providers: [
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: IDF_ADDRESS, useValue: environment.apiBaseUrls.idf },
        {
            provide: CMF_ADDRESS,
            useValue: environment.apiBaseUrls.cmf,
        },
        {
            provide: RMF_ADDRESS,
            useValue: environment.apiBaseUrls.rmf,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
