<div class="row justify-content-center" *ngIf="loading">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>
<div *ngIf="!loading">
    <div *ngIf="error" class="row justify-content-center">
        Sorry, we were unable to determine how you have been directed here.
        <br />
        Please check the link you've received is correct and try again or contact
        support@precision-autonomy.com for help.
    </div>
    <div *ngIf="!error" class="row justify-content-center">
        <form class="col-4" [formGroup]="form" (ngSubmit)="submit()">
            <formly-form [form]="form" [model]="model" [fields]="fields" [options]="options"></formly-form>

            <div class="row justify-content-center">
                <button mat-raised-button type="submit" class="col-md-3 col-11 btn btn-primary" [disabled]="form.invalid" data-testid="next">
                    Submit
                </button>
            </div>
        </form>
    </div>
</div>