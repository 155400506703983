import { Component, EventEmitter, Input, NgZone, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ThemeService, THEME_NAME } from '@pa/lib-spa';
import { Behaviour, Organisation, Theme } from '@pa/sdk/idf';
import { GridOptions, ValueFormatterParams } from 'ag-grid-community';
import { sortBy } from 'lodash';
import { NgxSpinnerService } from 'ngx-spinner';
import { IdfService } from 'src/app/services/idf.service';

interface MappingBehaviour {
    _id: string;
    behaviour: string;
    rate: string;
    taxRate: string;
}

@Component({
    selector: 'add-organisation',
    templateUrl: './add-organisation.html',
    styleUrls: ['./add-organisation.scss'],
})
export class AddOrganisation implements OnInit {
    @ViewChild('organisationDialog', { static: false }) organisationDialog: TemplateRef<any> | undefined;
    @Input() behaviours: Behaviour[];
    dialogRef: MatDialogRef<any> | undefined;

    public model = {};
    public form = new FormGroup({});
    public options: any = {};
    public fields: FormlyFieldConfig[] = [
        {
            key: 'organisationName',
            defaultValue: '',
            focus: true,
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'Organisation Name',
                minLength: 10,
                maxLength: 60,
                pattern: /[A-Za-z ]+/,
                required: true,
            },
            validation: {
                messages: {
                    minLength: (err: any, field: FormlyFieldConfig) => {
                        return `"${field.form.get('organisationName').value}" is not a valid Organisation Name length`;
                    },
                    maxLength: (err: any, field: FormlyFieldConfig) => {
                        return `"${field.form.get('organisationName').value}" is not a valid Organisation Name length`;
                    },
                    pattern: (err: any, field: FormlyFieldConfig) => {
                        return `"${field.form.get('organisationName').value}" is not a valid Organisation Name`;
                    },
                },
            },
        },
    ];

    private theme: Theme;
    public mappedBehaviours: MappingBehaviour[] = [];
    public behaviourGridOptions: GridOptions = {
        columnDefs: [
            {
                headerName: 'Behaviour',
                field: 'behaviour',
                flex: 1,
                valueFormatter: (params: ValueFormatterParams) => params.value.toUpperCase(),
            },
            {
                headerName: 'Commmission Rate',
                field: 'rate',
                flex: 1,
                editable: true,
                singleClickEdit: true,
                valueFormatter: (params: ValueFormatterParams) => (isNaN(Number(params.value)) ? '0' : params.value),
            },
            {
                headerName: 'Commission Tax Rate',
                field: 'taxRate',
                flex: 1,
                editable: true,
                valueSetter: '0',
                singleClickEdit: true,
                valueFormatter: (params: ValueFormatterParams) => (isNaN(Number(params.value)) ? '0' : params.value),
            },
        ],
        rowHeight: 48,
        pagination: true,
        paginationPageSize: 3,
        overlayLoadingTemplate: '<spann>Table is loading...</span>',
        overlayNoRowsTemplate: '<span>No Behaviours are there to show</span>',
    };

    public loading: boolean = false;
    public error: boolean = false;
    public showAddOrganisation: boolean = true;
    public showOrganisation: boolean = false;
    public organisation: any;

    constructor(
        private spinner: NgxSpinnerService,
        public dialog: MatDialog,
        private themeService: ThemeService,
        private ngZone: NgZone,
        private idfService: IdfService
    ) {
        this.theme = themeService.default;
        this.themeService.theme.subscribe((theme) => {
            this.theme = theme;
        });
    }

    ngOnInit(): void {}

    resizeGrids() {
        this.behaviourGridOptions.api.sizeColumnsToFit();
    }

    async open() {
        if (this.organisationDialog) {
            this.showAddOrganisation = true;
            this.showOrganisation = false;
            this.loading = false;
            this.error = false;
            this.dialogRef = this.dialog.open(this.organisationDialog, {
                panelClass: THEME_NAME,
                width: '50%',
            });
            await this.mapBehaviours();
        }
    }

    close() {
        this.ngZone.run(() => this.dialogRef.close());
    }

    mapBehaviours() {
        this.mappedBehaviours = this.behaviours.map((b) => {
            return {
                _id: b._id,
                behaviour: `${b.product} ${b.locale.split('-')[0]} ${b.market}`,
                rate: '0',
                taxRate: '0',
            };
        });

        sortBy(this.mappedBehaviours, ['behaviour']);
    }

    async addOrganisation() {
        this.loading = true;
        this.showAddOrganisation = false;
        this.showOrganisation = false;
        this.error = false;

        const commission = await this.mappedBehaviours.map((b: MappingBehaviour) => {
            delete b.behaviour;

            let finalizedBehaviour = Object.assign({}, b, {
                behaviour: b._id,
                rate: Number(b.rate),
                taxRate: Number(b.taxRate),
            });

            delete finalizedBehaviour._id;
            return finalizedBehaviour;
        });

        const organisationName: string = this.form.getRawValue().organisationName;
        let organisation = {
            name: organisationName,
            landingUrl: organisationName,
            supportEmail: 'support@precision-autonomy.com',
            commission,
            behaviours: await this.mappedBehaviours.map((b) => b._id),
        };

        this.form.setValue({ organisationName: '' });

        this.organisation = await this.idfService.saveOrganisation(organisation).toPromise();

        if (this.organisation) {
            this.loading = false;
            this.showAddOrganisation = false;
            this.showOrganisation = true;
            this.error = false;
            return;
        }

        this.loading = false;
        this.showAddOrganisation = false;
        this.showOrganisation = false;
        this.error = true;
    }
}
