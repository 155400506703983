import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Market, marketDisplay, ProductType } from '@pa/references/idf';
import { iso3166 } from '@pa/references/iso3166';
import { Behaviour } from '@pa/sdk/idf';
import { GridOptions, RowClickedEvent } from 'ag-grid-community';
import { Auth } from 'aws-amplify';
import { QuoteService } from '../services/quote.service';
import { distinct } from '../utils/array';
import { AddOrganisation } from './dialog/add-organisation/add-organisation';
import { AddOriginator } from './dialog/add-originator/add-originator';

interface OptionsValue {
    label: string;
    value: string;
}

interface ProductOption {
    label: string;
    value: ProductType;
}

@Component({
    selector: 'app-distribution-management',
    templateUrl: './distribution-management.component.html',
    styleUrls: ['./distribution-management.component.scss'],
})
export class DistributionManagementComponent implements OnInit {
    @ViewChild('organisationDialog') organisationDialog: AddOrganisation;
    @ViewChild('originatorDialog') originatorDialog: AddOriginator;

    public model: any = {};
    public options = {};
    public form = new FormGroup({});
    public fields: FormlyFieldConfig[] = [
        {
            key: 'product',
            type: 'select-extended',
            defaultValue: [],
            templateOptions: {
                attributes: {
                    'data-testid': 'product',
                },
                label: 'Select a product',
                required: true,
                options: [],
            },
            wrappers: ['form-field'],
            expressionProperties: {
                'templateOptions.options': () => this._productMap,
            },
            hooks: {
                onInit: (field: FormlyFieldConfig) => {
                    field.formControl.valueChanges.subscribe({
                        next: (p) => {
                            const behaviours = this._behaviours.filter((b) => b.product === p);
                            this._countryOptions(behaviours);
                            this._markets = distinct(behaviours.map((b) => b.market));
                        },
                    });
                },
            },
        },
        {
            key: 'country',
            type: 'select-extended',
            templateOptions: {
                attributes: {
                    'data-testid': 'country',
                },
                label: 'Country',
                required: true,
                options: [],
            },
            wrappers: ['form-field'],
            hideExpression: () => this._countries.length == 0,
            expressionProperties: {
                'templateOptions.options': () => this._countries,
            },
            hooks: {
                onInit: (field: FormlyFieldConfig) => {
                    field.formControl.valueChanges.subscribe({
                        next: (c) => {
                            const behaviours = this._behaviours.filter(
                                (b) => b.product === this.model.product && b.locale.includes(c)
                            );
                            this._marketOptions(behaviours);
                            this._markets = distinct(behaviours.map((b) => b.market));
                        },
                    });
                },
            },
        },
        {
            key: 'market',
            type: 'select-extended',
            templateOptions: {
                attributes: {
                    'data-testid': 'market',
                },
                label: 'Select your market',
                required: true,
                options: [],
            },
            wrappers: ['form-field'],
            hideExpression: () => this._markets.length < 2,
            expressionProperties: {
                'templateOptions.options': () =>
                    this._markets
                        .map((m) => ({ label: marketDisplay[m], value: m }))
                        .sort((a, b) => (a.label < b.label ? -1 : a.label > b.label ? 1 : 0)),
            },
        },
    ];

    public organisationGridOptions: GridOptions = {
        columnDefs: [
            {
                headerName: 'Organisation Name',
                field: 'organisationName',
                flex: 1,
            },
        ],
        rowHeight: 48,
        overlayNoRowsTemplate: '<span>No Organisations are there to show</span>',
        // TODO: Implement functionallity to pass the data to /viewOrganisation to show the originator details
        onRowClicked: (event: RowClickedEvent) => {
            this.router.navigate([`/viewOrganisation?id=`]);
        },
    };
    public organisations = [];

    private _countryMap: OptionsValue[] = iso3166.map((i) => ({ label: i.name, value: i.code }));
    private _productMap: ProductOption[] = [
        { label: 'Drones', value: 'drone' },
        { label: 'Aviation', value: 'aviation' },
    ];
    public _behaviours: Behaviour[] = [];
    private _countries: OptionsValue[] = [];
    private _markets: Market[] = [];
    private userGroups: string[];
    public savedOriginator: any;
    public savedOrganisation: any;

    constructor(private quoteService: QuoteService, private router: Router, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.getSessionDetails();
    }

    public resizeGrids(): void {
        this.organisationGridOptions.api?.sizeColumnsToFit();
    }

    private async getSessionDetails() {
        const auth = await Auth.currentSession();
        this.userGroups = (auth as any).accessToken.payload['cognito:groups'];
        await this.getBehaviours();
    }

    private getBehaviours(): void {
        this.userGroups.forEach(async (userGroup) => {
            const [insurer, product] = userGroup.split('.');
            const behaviour = await this.quoteService.getBehaviour('', insurer, product).toPromise();
            this._behaviours.push(behaviour);
        });
    }

    private _countryOptions(behaviours: Behaviour[]): void {
        const countries = behaviours.map((b) => b.locale.split('-')[0]);

        this._countries = this._countryMap.filter((c) => countries.includes(c.value));
        if (this._countries.length === 1) {
            this.model.country = this._countries[0].value;
        }
        this._marketOptions(behaviours);
    }

    private _marketOptions(behaviours: Behaviour[]): void {
        const markets = distinct(behaviours.map((b) => b.market));

        this._markets = markets;
        if (markets.length === 1) {
            this.model.market = markets[0];
        } else {
            delete this.model.market;
        }
    }

    // TODO: Get organisations for the selected behaviour and then update it to the table
    public searchOrganisation(): void {
        const { product, country } = this.form.getRawValue();
        this.router.navigate([], {
            relativeTo: this.route,
            queryParamsHandling: 'merge',
            queryParams: {
                product,
                country,
            },
            skipLocationChange: false,
        });
        // const selectedBehaviours = this._behaviours.filter(
        //     (b) => b.product === product && b.locale.split('-')[0] === country
        // ) as Behaviour[];
        this.organisations = [];
    }

    public openOriginatorModal(): void {
        this.originatorDialog.open();
    }

    public openOrganisationModal(): void {
        this.organisationDialog.open();
    }
}
