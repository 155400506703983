<!-- Title -->
<div id="title-bar">
    <span class="col-12">
        <h1>Search Quotes & Policies</h1>
    </span>
</div>

<div>
    <!-- Behaviour Selector -->
    <div class="mb-4">
        <div *ngIf="showBehaviourSelector">
            <app-behaviour-selection (selectedBehaviours)="updateBehaviour($event)"></app-behaviour-selection>
        </div>
        <div class="row justify-content-center" *ngIf="!showBehaviourSelector">
            <button mat-raised-button data-testid="showBehaviourSelector" (click)="showBehaviourSelector=!showBehaviourSelector">Show Behaviour
                Selector</button>
        </div>
    </div>

    <!-- Search Input Field -->
    <mat-form-field class="d-flex justify-content-center my-3">
        <input matInput type="text" placeholder="Search by Insured name" data-testid="searchInput" [value]="searchQuery"
            (input)="search$.next($event.target.value)" [disabled]="searchDisabled">
    </mat-form-field>

    <!-- Table -->
    <mat-card *ngIf="showTable" data-testid="showTable">
        <div class="row justify-content-center" *ngIf="dataLoading">
            <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <div *ngIf="!dataLoading">
            <ag-grid-angular 
                class="w-100 ag-theme-material" 
                domLayout="autoHeight" 
                [gridOptions]="gridOptions"
                [pagination]="true"
                [paginationPageSize]="paginationPageSize"
                [rowData]="rowData"
                (gridReady)="onGridReady($event)"
            >
            </ag-grid-angular>
        </div>
    </mat-card>
</div>