import { Injectable } from '@angular/core';
import { AuthState, CognitoUserInterface } from '@aws-amplify/ui-components';
import { BehaviorSubject, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
    public userSignedInSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    private _authState = new BehaviorSubject<AuthState | undefined>(undefined);
    private _authData = new BehaviorSubject<CognitoUserInterface | undefined>(undefined);

    public authState = this._authState.asObservable();
    public userGroups = this._authData.asObservable().pipe(map(data => (data?.signInUserSession?.idToken?.payload['cognito:groups'] ?? []) as string[]));

    setAuthState(state: AuthState) {
        this._authState.next(state);
    }

    setAuthData(data: CognitoUserInterface) {
        this._authData.next(data);
    }
}
