import _ from 'lodash';
import { Component, EventEmitter, NgZone, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {THEME_NAME, ThemeService} from '@pa/lib-spa';
import { PriceType, TransactionType } from '@pa/references/idf';
import { assetTypes } from '@pa/references/paul-precision';
import { Theme, QuotePriceInput } from '@pa/sdk/idf';
import { PolicyPrice } from '@pa/sdk/cmf';

import { AssetModel, PremiumEntry } from './types';
import { diffPrice } from './common/utils';

@Component({
    selector: 'edit-asset-modal',
    templateUrl: './asset.modal.html',
})
export class EditAssetModalComponent implements OnInit {
    @ViewChild('editAsset', { static: false }) editAssetRef: TemplateRef<any> | undefined;
    @Output() modelDataChanged = new EventEmitter<AssetModel>();
    dialogRef: MatDialogRef<any> | undefined;

    theme: Theme;
    model: AssetModel;
    isValid: boolean = false;
    title: string;

    constructor(public dialog: MatDialog, private themeService: ThemeService, private ngZone: NgZone) {
        this.theme = themeService.default;
        themeService.theme.subscribe((theme) => {
            this.theme = theme;
        });
    }

    ngOnInit() {}

    open(data: any) {
        this.model = data;
        this.title = this.getTitle(data);
        if (this.editAssetRef) {
            this.dialogRef = this.dialog.open(this.editAssetRef, {
                data,
                panelClass: THEME_NAME,
            });
        }
        this.dialogRef.backdropClick().subscribe(() => this.close());
    }
    close() {
        this.ngZone.run(() => this.dialogRef?.close());
    }
    update() {
        const model = this.model;

        if (!model.priceYearArray) {
            model.priceYearArray = [];
        }
        if (!model.priceYearAdjusted) {
            model.priceYearAdjusted = [];
        }

        model.premiums?.forEach((premium) => {
            let priceYear: QuotePriceInput = model.priceYearArray?.find(
                (pya) => pya.type === premium.type
            ) as QuotePriceInput;
            let priceYearAdjusted: QuotePriceInput = model.priceYearAdjusted?.find(
                (pya) => pya.type === premium.type
            ) as QuotePriceInput;

            if (!priceYear) {
                priceYear = { type: premium.type };
                this.setPriceYearPremiumValue(priceYear, premium);
                model.priceYearArray?.push(priceYear);
            } else {
                this.setPriceYearPremiumValue(priceYear, premium);
            }

            if (!priceYearAdjusted) {
                priceYearAdjusted = { type: premium.type };
                this.setPriceYearPremiumValue(priceYearAdjusted, premium);
                model.priceYearAdjusted?.push(priceYearAdjusted);
            }

            let policyPriceYear: PolicyPrice | undefined;
            if ([TransactionType.amendment, TransactionType.cancellation].includes(model.transactionType)) {
                policyPriceYear = model.assetPolicy?.priceYearArray?.find((ap) => ap.type === premium.type);
            }

            Object.assign(
                priceYearAdjusted,
                diffPrice(priceYear, policyPriceYear ?? { type: premium.type }, model.agreedValue ?? 0)
            );

            if (premium.type === PriceType.hull) {
                model.rateYear = premium.newValue;
                model.priceYearHull = premium.proRataPremium;
            } else if (premium.type === PriceType.csl) {
                model.priceYearCsl = premium.proRataPremium;
            } else if (premium.type === PriceType.tpl) {
                model.priceYearTpl = premium.proRataPremium;
            }
        });

        model.priceYearTotal = _.round(
            model.premiums?.reduce((acc, cur) => acc + (cur.proRataPremium ?? 0), 0) ?? 0,
            2
        );

        this.modelDataChanged.emit(model);
        this.close();
    }

    private setPriceYearPremiumValue = (priceYear: QuotePriceInput, premium: PremiumEntry): void => {
        if (premium.type === PriceType.hull) {
            priceYear.rate = premium.newValue;
            priceYear.hull = premium.newPremium;
        } else {
            priceYear.liability = premium.newPremium;
        }
    };

    onValidated(isValid: boolean) {
        this.isValid = isValid;
    }

    getTitle = (model: AssetModel): string => {
        switch (model.type) {
            case assetTypes.aircraft:
                return 'Aircraft Details';
            case assetTypes.uav:
                return 'Drone Details';
            case assetTypes.uavEquipment:
                return 'Equipment Details';
            case assetTypes.uavPayload:
                return 'Payload Details';
        }

        return '';
    };
}
