import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviourService, mapAttachments } from '@pa/lib-spa';
import { Attachment, Behaviour } from '@pa/lib-spa/types';
import { TransactionType } from '@pa/references/idf';
import { ClientPolicy } from '@pa/sdk/cmf';
import { PolicyService } from '../services/policy.service';
import { QuoteService } from '../services/quote.service';
import { SdkService } from '../services/sdk.service';
import { PaClient } from '@pa/sdk/rmf';
import { Auth } from 'aws-amplify';
import { formatDate } from '../utils/date';
import { switchMap } from 'rxjs/operators';
import { mapDocumentationLinks } from '../core/utils/attachment.mapping';

@Component({
  selector: 'app-view-policy',
  templateUrl: './view-policy.component.html',
  styleUrls: ['./view-policy.component.scss']
})
export class ViewPolicyComponent implements OnInit {
  attachments: Attachment[];
  behaviourId: string = '';
  behaviour: Behaviour;
  clientPolicy: ClientPolicy;
  error: boolean = false;
  loading: boolean = true;
  paClient: PaClient;
  policyId: string = '';
  showInceptionDate: boolean = true;
  formatDate = formatDate;

  constructor(
    private behaviourService: BehaviourService,
    private policyService: PolicyService,
    private quoteService: QuoteService,
    private router: ActivatedRoute,
    private sdkService: SdkService,
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.error = false;

    this.router.queryParams.pipe(
      switchMap((params) => {
        this.policyId = params.policyId;
        this.behaviourId = params.behaviourId;
        return this.quoteService.getBehaviour(this.behaviourId);
      }),
      switchMap((behaviour) => {
        this.behaviour = behaviour;
        this.behaviourService.set(behaviour);
        return this.policyService.getPolicy(this.policyId, this.behaviourId);
      })
    ).subscribe({
      next: async (policy) => {
        this.clientPolicy = policy;

        const currentSession = await Auth.currentSession();
        this.sdkService.rmf.accessToken = currentSession.getAccessToken().getJwtToken();
        this.paClient = await this.sdkService.rmf.Clients.getById(this.clientPolicy.behaviour, this.clientPolicy.paClient);

        const documentlinks: Attachment[] = mapDocumentationLinks(this.clientPolicy.documentationLinks);
        this.attachments = mapAttachments(documentlinks, this.behaviour, TransactionType.amendment);

        if (this.clientPolicy.renewalDate) {
          this.showInceptionDate = (this.clientPolicy.inceptionDate >= this.clientPolicy.renewalDate) ? true : false;
        }

        this.loading = false
        this.error = false
      },
      error: (err) => {
        this.loading = false;
        this.error = true;
      }
    })
  }
}
