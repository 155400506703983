import * as adressFormatter from '@fragaria/address-formatter';
import { iso3166 } from '@pa/references/iso3166';
import { ProposedClient } from '@pa/sdk/idf';
export function mapClientAddress(company: ProposedClient, oneLine = false) {
    const {
        address1,
        address2,
        city,
        postalCode: postcode,
        subdivision: stateCode,
        country: countryCode,
    } = company.address;
    const country = iso3166.find((c) => c.code === countryCode);
    const state = country.subdivisions.find((s) => s.code === stateCode);
    let formattedAddress:string | string[] = adressFormatter.format(
        {
            road: address1 + (address2 ? ' ' + address2 : ''),
            city,
            postcode,
            state: state?.name,
            country: country.name,
        },
        {
            countryCode: country.code,
            abbreviate: false,
            appendCountry: false,
            cleanupPostcode: false,
            fallbackCountryCode: '',
            output: 'array'
        }
    );
    if (oneLine) {
        // There is a linebreak at the end of the string, so need to slice the last 2 characters
        if (typeof formattedAddress === 'string') {
            formattedAddress = formattedAddress.replace(/\r?\n/g, ', ').slice(0, -2);
        } else {
            formattedAddress = formattedAddress.map(fa => fa.replace(/\r?\n/g, ', ').slice(0, -2))
        }
    }

    return formattedAddress;
}
